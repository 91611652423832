import React, { useEffect, useState } from 'react';
import * as yup from 'yup'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { enregistrerservice } from '../../services/prestataires/service';
import $ from 'jquery';
import { Oval } from 'react-loader-spinner';
import { Autocomplete, TextField, Box } from '@mui/material';
import { rechercherpays } from '../../services/parametres/pays';
import { rechercheroperateurparpays } from '../../services/parametres/operateur';
import { useParams } from 'react-router';


function AjoutService(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const [selectedValue, setselectedValue] = useState([]);
    const [paysSelected, setpaysSelected] = useState("");
    const [listeOperateur, setlisteOperateur] = useState([]);
    const [listePays, setlistePays] = useState([])
    const {idPrestataire} = useParams();
    
    const initialiValue = {
        id: Object.keys(props.datas).length === 0 ? "" : props.datas.id,
        designation: Object.keys(props.datas).length === 0 ? "" : props.datas.designation,
        urlNotification: Object.keys(props.datas).length === 0 ? "" : props.datas.urlNotification,
        description: Object.keys(props.datas).length === 0 ? "" : props.datas.description,
        pays_id: Object.keys(props.datas).length === 0 ? "" : props.datas.pays_id

    }
    const validationvaluesschema = yup.object().shape({
        designation: yup.string().required("Veuillez entrer la désignation"),
        urlNotification: yup.string().required("Veuillez entrer l'url de notification"),

    })

    const afficherListePays = () => {
        rechercherpays().then((res) => {
            setlistePays(res.data.donnee);
        })
    }

    const afficherOperateurs = (id) => {
        rechercheroperateurparpays(id).then((res) => {
            setlisteOperateur(res.data.donnee);
        })
    }

    const handleSubmitService = (values) => {
        setloading(true)
        values.pays_id = paysSelected;
        let val = Object.assign(values, { "operateur": selectedValue, "prestataire_id": idPrestataire })
        enregistrerservice(val).then((res) => {
            setloading(false);
            props.afficherService();
            setmessageError("");
            setpaysSelected("");
            setselectedValue([]);
            $('#fermerModalService').trigger('click');
        }).catch((err) => {
            setloading(false);
            (err.response.data.donnee.pays_id !== undefined) && setmessageError("Veuillez sélectionner le pays");
            (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);
        });
    }

    const handlePays = (value) => {
        setpaysSelected(value);
        setselectedValue([]);
        value !== "" ? afficherOperateurs(value) : setlisteOperateur([])
    }


    useEffect(() => {
        afficherListePays()
    }, [])

    useEffect(() => {
        props.datas.pays_id !== undefined && setpaysSelected(props.datas.pays_id);
    }, [props.datas])

    const optionListPays = listePays.map((item, index) => (
        <option key={index} value={item.id}>{item.designation}</option>
    ))

    return <div className="modal fade" id="ajoutService" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">Service</h5>
                    <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">
                    <Formik
                        initialValues={initialiValue}
                        onSubmit={handleSubmitService}
                        enableReinitialize
                        validationSchema={validationvaluesschema}
                    >
                        {({ resetForm }) => (
                            <Form>
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}
                                <div className="mb-3">
                                    <label htmlFor="designation" className='form-label'>Désignation</label>
                                    <Field type="text" className='form-control' name='designation' id='designation' />
                                    <p className='text-danger'><ErrorMessage name='designation'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="urlNotification" className='form-label'>URL de notification</label>
                                    <Field type="text" className='form-control' id='urlNotification' name="urlNotification" />
                                    <p className='text-danger'><ErrorMessage name="urlNotification"></ErrorMessage></p>
                                </div>
                                {props.datas.id === undefined && <div className='mb-3'>
                                    <label htmlFor="pays_id" className='form-label'>Pays</label>
                                    <Field component="select" onChange={(e) => handlePays(e.target.value)} value={paysSelected} name="pays_id" className='form-select' id="pays_id">
                                        <option value="">Veuillez sélectionner le pays</option>
                                        {optionListPays}
                                    </Field>

                                </div>
                                }
                                {props.datas.id === undefined && <div className="mb-3">
                                    <label htmlFor="listeOperateurSelected" className='form-label'>Opérateur</label>
                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        isOptionEqualToValue={(option, value) => option.id == value.id}
                                        filterSelectedOptions
                                        value={selectedValue}
                                        onChange={(_event, newItem) => {
                                            setselectedValue(newItem);
                                        }}
                                        id="listeOperateurSelected"
                                        options={listeOperateur}
                                        getOptionLabel={(option) => option.designation}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.designation+" | "} {option.id}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Sélectionner le(s) opérateur(s) .. " placeholder="Opérateur" />
                                        )}
                                        sx={{ width: '100%' }}
                                    />

                                </div>
                                }
                                <div className="mb-3">
                                    <label htmlFor="description" className='form-label'>Description</label>
                                    <Field as="textarea" name="description" id="description" className="form-control"> </Field>
                                </div>
                                <div className="modal-footer bg-white">
                                    <button type="button" id='fermerModalService' onClick={resetForm} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    </div >
}

export default AjoutService;
