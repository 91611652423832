import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const enregistrerpays = async(values) =>{
    return await axios.post(baseUrl("enregistrerpays"),values,headers);
};

export const rechercherpays = async() =>{
    return await axios.get(baseUrl("rechercherpays"),headers);
}

export const supprimerpays = async(code) =>{
    return await axios.delete(baseUrl("supprimerpays/"+code),headers);
}