import React, { useEffect, useState } from 'react';
import Layout from '../../layouts/Layout';
import AjoutReversement from './AjoutReversement';
import { rechercherallprestataires, rechercherprestataire } from '../../services/prestataires/prestatairesRequest'
import { rechercherreversementparservice } from '../../services/reversement/reversementRequest';
import { rechercherallservices, rechercherserviceparprestataire } from '../../services/prestataires/service';
import Paginate from '../pagination';
import { Oval } from 'react-loader-spinner';

function Reversements() {

    const [listeReversement, setlisteReversement] = useState([]);
    const [listePrestataires, setlistePrestataires] = useState([]);
    const [listeServices, setlisteServices] = useState([]);
    let [filterParametres, setfilterParametres] = useState({
        commentaire: "",
        date: "",
        montant: "",
        prestataire_id: "",
        service_id: ""
    });

    const [totalElement, settotalElement] = useState(0);
    const [totalElementByPage, setTotalElementByPage] = useState(0);
    const [pageActive, setPageActive] = useState(1);
    const [loading, setloading] = useState(false)

    const handlePageChange = (pageNumber) => {
        setPageActive(pageNumber);
        afficherreversements(pageNumber);
    }

    const afficherPrestataires = () => {
        rechercherallprestataires().then((res) => {
            setlistePrestataires(res.data.donnee);
        })
    }


    const afficherreversements = (currentPage) => {
        setloading(true)
        rechercherreversementparservice(filterParametres, currentPage).then((res) => {
            setlisteReversement(res.data.donnee.data);
            settotalElement(res.data.donnee.total);
            setTotalElementByPage(res.data.donnee.per_page);
            setloading(false);
        }).catch((error)=>{
            setloading(false);
            console.log(error.response.data);
        })
    }
    const displayAllservice = () => {
        rechercherallservices().then((res) => {
            setlisteServices(res.data.donnee);
        })
    }

    const afficherService = (id) => {
        rechercherserviceparprestataire(id).then((res) => {
            setlisteServices(res.data.donnee);
        })
    }

    //Filtrer par prestataire
    const filterOnPrestataire = (e) => {
        e.target.value !== "" ? afficherService(e.target.value) : displayAllservice();
        filterParametres.prestataire_id = e.target.value;
        filterParametres.service_id = "";
        setfilterParametres(filterParametres);
        afficherreversements(filterParametres, pageActive)
    }

    //Filtrer par Service
    const filterOnService = (e) => {
        filterParametres.service_id = e.target.value;
        setfilterParametres(filterParametres);
        afficherreversements(filterParametres, pageActive)
    };



    useEffect(() => {
        afficherPrestataires();
        displayAllservice();
        afficherreversements(pageActive);
    }, []);

    const rowListeReversement = listeReversement.map((item, index) => (
        <tr key={index}>
            {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                <td>
                    {item.service.prestataire.nom}
                </td>
            }
            <td>{item.service.designation} </td>
            <td>{item.montant}</td>
            <td>{item.dateVersement}</td>
            <td>{item.commentaire}</td>
        </tr>
    ));

    const optionPrestataire = listePrestataires.map((item) => (
        <option key={item.id} value={item.id}>{item.nom}</option>
    ));
    const optionListeService = listeServices.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));


    return <Layout>
        <div>

            {/* <div className='search d-flex justify-content-center mt-1'>
                <div className="ui icon input">
                    <input type="text" placeholder="Search..." />
                    <i className="circular search link icon"></i>
                </div>
            </div> */}
            <div className='container'>
                <div className='d-flex justify-content-between mt-5'>
                    <div className='fw-bold'>
                        <legend>Reversements</legend>
                    </div>
                    <div className='d-flex'>
                        <div className='d-flex mx-3'>
                            {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                                <select onChange={(e) => filterOnPrestataire(e)} value={filterParametres.prestataire_id} className="form-select mx-2 form-select-sm" aria-label=".form-select-sm example">
                                    <option value="">Sélectionner le prestataire</option>
                                    {optionPrestataire}
                                </select>
                            }
                            <select onChange={(e) => filterOnService(e)} value={filterParametres.service_id} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                <option value="">Sélectionner le service</option>
                                {optionListeService}
                            </select>
                        </div>
                        
                        {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                            <div>
                                <a type='button' data-bs-toggle="modal" data-bs-target="#ajoutReversement" className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau reversement</a>
                                <AjoutReversement setPageActive={setPageActive} listePrestataire={listePrestataires} afficherreversements={afficherreversements} />
                            </div>
                        }
                    </div>
                </div>
                <table className="ui celled padded table">
                    <thead>
                        <tr>
                            {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                                <th className="single line">Prestataire</th>
                            }
                            <th>Service</th>
                            <th>Montant</th>
                            <th>Date</th>
                            <th>Commentaire</th>
                        </tr></thead>
                    <tbody>
                        {rowListeReversement}
                    </tbody>
                    <tfoot>
                        <tr><th colSpan="6">
                            <div className='d-flex justify-content-center'>
                                <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
                            </div>
                        </th>
                        </tr></tfoot>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
            </div>
        </div>
    </Layout>;
}

export default Reversements;
