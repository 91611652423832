import React, { useEffect, useState } from 'react';
import * as yup from 'yup'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import $ from 'jquery';
import { registeruserservice, updateuserservice } from '../../services/prestataires/service';
import { Oval } from 'react-loader-spinner';

function AjoutUtilisateur(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const [isAdminPrestataire, setisAdminPrestataire] = useState(false);
    const initialiValue = {
        id: Object.keys(props.datas).length === 0 ? "" : props.datas.id,
        nom: Object.keys(props.datas).length === 0 ? "" : props.datas.nom,
        prenoms: Object.keys(props.datas).length === 0 ? "" : props.datas.prenoms,
        numero: Object.keys(props.datas).length === 0 ? "" : props.datas.numero,
        email: Object.keys(props.datas).length === 0 ? "" : props.datas.email,
        fonction: Object.keys(props.datas).length === 0 ? "" : props.datas.fonction,
        prestataire_id: props.prestataireId
    }
    const validationvaluesschema = yup.object().shape({
        nom: yup.string().required("Veuillez saisir un nom"),
        prenoms: yup.string().required("Veuillez saisir au moins un prénom"),
        email: yup.string().required("Veuillez saisir un email").email("Veuillez saisir un email valide"),
        id: Object.keys(props.datas).length !== 0 ? yup.string() : yup.string().required("Veuillez saisir le login"),
        fonction: yup.string().required("Veuillez saisir la fonction"),
        numero: yup.string().matches("[0-9]+", "Veuillez saisir des chiffres entre 0 et 9").required("Veuillez entrer un numéro"),
    })

    const handleSubmitUtilisateur = (values) => {
        setloading(true)
        Object.keys(props.datas).length !== 0 ?
        updateuserservice(values).then((res) => {
            setloading(false)
            setisAdminPrestataire(false);
            props.afficherUtilisateurs();
            $('#fermerModalUtilisateur').trigger('click');
        }).catch((err) => {
            setloading(false);
            (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);

        })
        :
        registeruserservice(values).then((res) => {
            setloading(false)
            setisAdminPrestataire(false);
            props.afficherUtilisateurs();
            $('#fermerModalUtilisateur').trigger('click');
        }).catch((err) => {
            setloading(false);
            console.log(err.response.data);
            (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);

        })
    }


    return <div className="modal fade" id="ajoutUtilisateur" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">Utilisateur</h5>
                    <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">


                    <Formik
                        initialValues={initialiValue}
                        onSubmit={handleSubmitUtilisateur}
                        enableReinitialize
                        validationSchema={validationvaluesschema}
                    >
                        {({ resetForm }) => (
                            <Form>
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                <div className="mb-3">
                                    <label htmlFor="nom" className='form-label'>Nom</label>
                                    <Field type="text" className='form-control' id='nom' name="nom" />
                                    <p className='text-danger'><ErrorMessage name="nom"></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="prenoms" className='form-label'>Prénoms</label>
                                    <Field type="text" className='form-control' id='prenoms' name="prenoms" />
                                    <p className='text-danger'><ErrorMessage name="prenoms"></ErrorMessage></p>
                                </div>
                                {Object.keys(props.datas).length === 0 && <div className="mb-3">
                                    <label htmlFor="login" className="form-label">Login</label>
                                    <Field type="text" name='id' className="form-control" id="login" />
                                    <p className='text-danger'><ErrorMessage name='id'></ErrorMessage></p>
                                </div>
                                }
                                <div className="mb-3">
                                    <label htmlFor="username" className='form-label'>E-mail</label>
                                    <Field type="text" className='form-control' name='email' id='username' />
                                    <p className='text-danger'><ErrorMessage name='email'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="numero" className='form-label'>Numéro</label>
                                    <Field type="text" className='form-control' id='numero' name="numero" />
                                    <p className='text-danger'><ErrorMessage name="numero"></ErrorMessage></p>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fonction" className='form-label'>Fonction</label>
                                    <Field type="text" className='form-control' id='fonction' name="fonction" />
                                    <p className='text-danger'><ErrorMessage name="fonction"></ErrorMessage></p>
                                </div>

                                <div className="modal-footer bg-white">
                                    <button type="button" id='fermerModalUtilisateur' onClick={() => { resetForm(); setmessageError("") }} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    </div >
}

export default AjoutUtilisateur;
