import "./App.css";
import { HashRouter } from "react-router-dom";
// import { BrowserRouter as Router } from 'react-router-dom';
import RoutesApp from "./RoutesApp";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <HashRouter hashType="noslash">
      <RoutesApp />
    </HashRouter>
    // <Router>
    //     <RoutesApp />
    // </Router>
  );
}

export default App;
