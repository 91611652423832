import React, { useEffect, useState } from "react";
import { rechercherprestatairestats } from "../../services/statistiques/StatistiquesRequest";
import { rechercherpaiement } from "../../services/transactions/transactionsRequest";
import DateFilter from "../DateFilter";
import Paginate from "../pagination";
import moment from 'moment'
import { Oval } from 'react-loader-spinner';
import { convertNumber } from "../../convertToMoneyFormat";

function DetailsTransactionClient(props) {

    const [loading, setloading] = useState(false);
    const [filterParametres, setfilterParametres] = useState({
        codeOperateur: "",
        dateDebut: "",
        dateFin: "",
        param: "",
        prestataire_id: props.idPrestataire,
        service_id: ""
    });


    //Recherche sur la date
    const filterOnDate = (dateDebut, dateFin) => {
        filterParametres.dateDebut = (dateDebut !== null && dateDebut !== "") ? moment(dateDebut).format("yyyy-MM-DD") : "";
        filterParametres.dateFin = (dateFin !== null && dateFin !== "") ? moment(dateFin).format("yyyy-MM-DD") : "";
        filterParametres.prestataire_id = props.idPrestataire;
        setfilterParametres(filterParametres);
        AfficherPaiementFilter(filterParametres, 1);
    }

    const montantApayerParService = (idService) => {
        rechercherprestatairestats(
            {
                prestataires_id: props.infoPrestataire.id,
                service_id: idService
            }).then((res) => {
                props.handlesMmontantAreverser(res.data.donnee);
            })
    }

    //Filtrer par Service
    const filterOnService = (e) => {
        montantApayerParService(e.target.value);
        filterParametres.service_id = e.target.value;
        filterParametres.prestataire_id = props.infoPrestataire.id;
        setfilterParametres(filterParametres);
        AfficherPaiementFilter(filterParametres, 1);
    };
    //Filtrer par opérateur
    const filterOnOperator = (e) => {
        filterParametres.codeOperateur = e.target.value;
        filterParametres.prestataire_id = props.idPrestataire;
        setfilterParametres(filterParametres);
        AfficherPaiementFilter(filterParametres, 1);
    };

    const handlePageChange = (pageNumber) => {
        props.setpageActive(pageNumber);
        filterParametres.prestataire_id = props.idPrestataire;
        (filterParametres.codeOperateur === "" &&
            filterParametres.dateDebut === "" &&
            filterParametres.dateFin === "" &&
            filterParametres.param === "" &&
            filterParametres.service_id === "") ? props.afficherTransactionPrestataire(props.idPrestataire, pageNumber) :
            AfficherPaiementFilter(filterParametres, pageNumber);
        ;
    };

    //Zone de recherche
    const handleSearchTransaction = (e) => {
        filterParametres.param = e.target.value;
        filterParametres.prestataire_id = props.infoPrestataire.id;
        setfilterParametres(filterParametres);
        AfficherPaiementFilter(filterParametres, 1);
    }

    //Afficher Paiement en founction du filtre
    const AfficherPaiementFilter = (filterParam, currentPage) => {
        setloading(true)
        rechercherpaiement(filterParam, currentPage).then((res) => {
            setloading(false)
            props.setpageActive(currentPage);
            props.handleListeTransactions([]);
            props.handleListeTransactions(res.data.donnee.data)
            props.settotalElementTransaction(res.data.donnee.total);
            props.setTotalElementByPageTransaction(res.data.donnee.per_page);
        }).catch((error) => (
            setloading(false)
        ));

        props.AfficherMontantAreverserService(filterParam)

    }


    const rowListeTransactions = props.listeTransactions.map((item, index) => (
        < tr key={index} >
            <td>{item.service !== null && item.service.designation} </td>
            <td>{item.reference}</td>
            <td>{item.operateurMobileMoney_id !== null && item.operateurMobileMoney_id}</td>
            <td>{item.numero}</td>
            <td>{item.valeur} </td>
            <td>{item.datePaiement !== undefined && moment(item.datePaiement).format("DD-MM-yyyy H:m") }</td>
            <td className={item.valide ? "text-success fw-bold" : "text-danger fw-bold"}>
                {item.valide ? "Succès" : "Echec"}
            </td>
        </tr >
    ));
    const optionListeOperateur = props.listeOperateur.map((item) => (
        <option key={item.code} value={item.id}>{item.designation +" | "+item.id}</option>
    ));

    const optionListeService = props.listeService.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));

    return <div className="offcanvas offcanvas-end blueSkanpay" style={{ width: "60%" }} tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header text-white header">
            <div className="content">
                Détails des transactions
                <div className="sub header colorTextSkanPay fw-bold">{props.infoPrestataire.nom}</div>
            </div>
            <div className="content">
                Montant à reverser
                <div className="sub header colorTextSkanPay fw-bold">
                { convertNumber(Math.floor(props.montantAreverser))}
                 <span className='mx-2'>FCFA</span> 
                
                </div>
            </div>

            <button type="button" className="btn-close bg-danger text-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div className="offcanvas-body bg-white">

            <div className='search d-flex justify-content-center mt-2'>
                <div className="ui icon input">
                    <input onChange={handleSearchTransaction} type="text" placeholder="Search..." />
                    <i className="circular search link icon"></i>
                </div>

            </div>

            <div className="mt-4">
                <div className='d-flex justify-content-end'>
                    <div className=" d-flex justify-content-end" >
                        <DateFilter filterElement={filterOnDate} />
                    </div>
                    <div className="d-flex">
                        <select onChange={(e) => filterOnOperator(e)} className="form-select form-select-sm mx-3" aria-label=".form-select-sm example">
                            <option value="">Sélectionner l'opérateur</option>
                            {optionListeOperateur}
                        </select>
                        <select onChange={(e) => filterOnService(e)} className="form-select form-select-sm" aria-label=".form-select-sm example">
                            <option value="">Sélectionner le service</option>
                            {optionListeService}
                        </select>
                    </div>
                </div>
                <table className="ui celled padded table">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Ref transaction</th>
                            <th>opérateur</th>
                            <th>Numéro</th>
                            <th>Montant</th>
                            <th>date</th>
                            <th>statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!loading && !props.loading) && rowListeTransactions}

                    </tbody>
                    <tfoot>
                        <tr>

                            <th colSpan="7">
                                <div className="d-flex justify-content-center">
                                    <Paginate pageActive={props.pageActive} handlePageChange={handlePageChange} nbreElementParPage={props.nbreElementParPage} nbreTotalElmentPage={props.nbreTotalElmentPage} />
                                </div>
                            </th>
                        </tr></tfoot>
                </table>
                {(loading || props.loading) &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading || props.loading} />
                    </div>
                }
            </div>

        </div>
    </div>
}

export default DetailsTransactionClient;
