import axios from "axios"
import { headers } from "../headers"
import { baseUrl } from "../URL"

export const enregistrerdemandereversement = async(values)=>{
    return axios.post(baseUrl("enregistrerdemandereversement"), values, headers);
}

export const rechercherdemandesreversement = async(values, page)=>{
    return axios.post(baseUrl("rechercherdemandesreversement?page="+page), values, headers);
}

export const changeretatreversement = async(id)=>{
    return axios.get(baseUrl("changeretatreversement/"+id), headers);
}