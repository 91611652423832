import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from "yup";
import { signin } from '../services/login/LoginRequest';
import { Navigate } from 'react-router';
import { isAuthenticated } from '../TokenValidation'
import MotdepasseOublie from './MotdepasseOublie';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

function Login() {

  const [errorAuthentication, seterrorAuthentication] = useState("");
  const [loading, setloading] = useState(false);

  const initialValues = {
    username: "",
    password: ""
  };

  const validationValuesSchema = yup.object().shape({
    username: yup.string().required("Veuillez entrer un login"),
    password: yup.string().required("Veuillez entrer un mot de passe")
  });

  const handleOnSubmit = (values) => {
    setloading(true);
    signin(values).then((res) => {
      setloading(false);
      localStorage.setItem("tokenskanpay", res.data.donnee.token);
      localStorage.setItem("skanpayuser", res.data.donnee.infosUser.email);
      localStorage.setItem("role", res.data.donnee.role[0].role_id);
      localStorage.setItem("prestataires", res.data.donnee.infosUser.prestataire_id);
      window.location.replace('/')
    }).catch((err) => {
      setloading(false);
      seterrorAuthentication(err.response.data.donnee.error)
    })
  }

  return <div className=' p-4 d-flex justify-content-center' style={{height : "100vh", backgroundColor:"#E5E5E5"}}>
    {
      isAuthenticated() && <Navigate to="/dashboard" />
    }
    <div style={{marginTop :"5%"}} className="w-50 shadow bg-white rounded px-5 py-2 h-50 ">

      <div >
        <div className='mx-3 text-center'>
          <img width={150} className='img-fluid' src="/images/LogoSKANnPayBleu.png" alt="Logo SKANPAY" />
        </div>
        
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationValuesSchema}
        onSubmit={handleOnSubmit}
      >
        <Form>

          <p className='alert-danger'>{errorAuthentication}</p>
          <div className='mb-3'>
            <label htmlFor="login" className='form-label fw-bold'>Login</label>
            <Field type="text" className='form-control' id='login' name='username' />
            <p className='text-danger'><ErrorMessage name='username'></ErrorMessage></p>
          </div>
          <div className='mb-3'>
            <label htmlFor="password" className='form-label fw-bold'>Mot de passe</label>
            <Field type="password" className='form-control' id='password' name='password' />
            <p className='text-danger'><ErrorMessage name='password'></ErrorMessage></p>
          </div>
          <div className='d-flex justify-content-between'>
            <Oval width={30} height={30} color="#344b7b" visible={loading} />
            {!loading && <button type='submit' className='btn text-white blueSkanpay'>Se connecter</button>}
            <div>
              <Link to="/inscription" className='btn btn-link'>S'inscrire</Link>
              <a className='btn btn-link' data-bs-toggle="modal" data-bs-target="#mdpOublie">Mot de passe oublié ?</a>
            </div>

          </div>
        </Form>
      </Formik>


      <MotdepasseOublie />
    </div>
  </div>
}

export default Login;
