import React, { useEffect, useState } from "react";
import { InfoToken } from "../../infoToken";
import Layout from "../../layouts/Layout";
import {
  changeStatut,
  currentuserconnected,
  getAllUser,
  profilUserConnected,
} from "../../services/utilisateurs/UtilisateursRequest";
import Paginate from "../pagination";
import AjoutUtilisateur from "./AjoutUtilisateur";
import { Oval } from "react-loader-spinner";
import UpdateProfilUtilisateur from "./UpdateProfilUtilisateur";

function Utilisateurs(props) {
  const [listeUtilisateurs, setlisteUtilisateurs] = useState([]);
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [filterParametres, setfilterParametres] = useState({ param: "" });
  const [pageActive, setPageActive] = useState(1);
  const [infoUser, setinfoUser] = useState({});
  const [loading, setloading] = useState(false);

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    afficherListeUtilisateur(pageNumber, filterParametres);
  };

  //Liste des utilisateurs
  const afficherListeUtilisateur = (currentPage, params) => {
    setloading(true);
    getAllUser(currentPage, params)
      .then((res) => {
        setloading(false);
        setlisteUtilisateurs(res.data.donnee.data);
        settotalElement(res.data.donnee.total);
        setTotalElementByPage(res.data.donnee.per_page);
      })
      .catch((error) => setloading(false));
  };

  useEffect(() => {
    afficherListeUtilisateur(pageActive, filterParametres);
  }, []);

  //Changement d'état de l'utilisateur
  const handleStatut = (id) => {
    setloading(true);
    changeStatut(id)
      .then((res) => {
        setloading(false);
        afficherListeUtilisateur(pageActive, filterParametres);
      })
      .catch((error) => setloading(false));
  };

  //Zone de recherche
  const handleSearchUser = (values) => {
    filterParametres.param = values.target.value;
    afficherListeUtilisateur(1, { params: values.target.value });
  };

  const trUsers = listeUtilisateurs.map((item, index) => (
    <tr
      key={index}
      onClick={() => setinfoUser(item)}
      className={item.recevoirDemandeReversement ? "text-warning" : ""}
    >
      <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
        {item.nomPrenoms}
      </td>
      <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
        {item.id}
      </td>
      <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
        {item.email}
      </td>
      {localStorage.getItem("role") === "ROLE_ADMIN" && (
        <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
          {item.roles.length !== 0 &&
            item.roles.map((element, id) => (
              <span key={id}>
                {element.pivot.role_id !== null &&
                  (element.pivot.role_id === "ROLE_ADMIN"
                    ? "Administrateur"
                    : element.pivot.role_id === "ROLE_ADMIN_PRESTATAIRE"
                    ? "Prestataire"
                    : element.pivot.role_id === "ROLE_UTILISATEUR_SERVICE"
                    ? "Utilisateur service"
                    : "")}{" "}
                {id + 1 !== item.roles.length && " | "}{" "}
              </span>
            ))}
        </td>
      )}

      <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
        {item.numero}
      </td>
      <td>
        <button className="btn btn-sm" onClick={() => handleStatut(item.id)}>
          {" "}
          {item.enabled ? (
            <span className="btn text-success">
              <i className="fa fa-sign-in-alt"></i> Actif
            </span>
          ) : (
            <span className="btn text-danger">
              {" "}
              <i className="fa fa-ban"></i> Inactif
            </span>
          )}{" "}
        </button>

        {localStorage.getItem("role") === "ROLE_ADMIN" && (
          <button
            onClick={() => setinfoUser(item)}
            data-bs-toggle="modal"
            data-bs-target="#updateProfilUtilisateur"
            className="btn btn-sm"
          >
            <i className="fa fa-pencil-alt"></i>
          </button>
        )}

        {/* <button onClick={()=>setinfoUser(item)} data-bs-toggle="modal" data-bs-target="#updateProfilUtilisateur" className='btn btn-sm'><i className='fa fa-pencil-alt'></i></button> */}
      </td>
    </tr>
  ));

  return (
    <Layout>
      <div>
        <div className="Filtre"></div>
        <div className="search d-flex justify-content-center mt-1">
          <div className="ui icon input">
            <input
              type="text"
              onChange={(e) => handleSearchUser(e)}
              placeholder="Search..."
            />
            <i className="circular search link icon"></i>
          </div>
        </div>
        <div className="container">
          <div className="d-flex justify-content-between mt-5">
            <div className="fw-bold">
              <legend>Utilisateurs</legend>
            </div>
            {localStorage.getItem("role") !== "ROLE_UTILISATEUR_SERVICE" && (
              <div className="">
                <a
                  type="button"
                  onClick={() => setinfoUser({})}
                  data-bs-toggle="modal"
                  data-bs-target="#ajoutUtilisateur"
                  className="btn colorYelloSkanPay text-black  btn-sm"
                >
                  {" "}
                  <i className="fa fa-plus-circle"></i> Nouveau Utilisateur
                </a>
                {/* <AjoutUtilisateur afficherListeUtilisateur={afficherListeUtilisateur} /> */}
              </div>
            )}
          </div>
          <table className="ui celled padded table">
            <thead>
              <tr>
                <th className="single line">Nom et prénoms</th>
                <th>Login</th>
                <th>E-mail</th>
                {localStorage.getItem("role") === "ROLE_ADMIN" && <th>Rôle</th>}
                <th>Numéro</th>
                <th width="15%">Statut</th>
              </tr>
            </thead>
            <tbody>{!loading && trUsers}</tbody>

            <tfoot>
              <tr>
                <th colSpan="7">
                  <div className="d-flex justify-content-center">
                    <Paginate
                      pageActive={pageActive}
                      handlePageChange={handlePageChange}
                      nbreElementParPage={totalElementByPage}
                      nbreTotalElmentPage={totalElement}
                    />
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
          {loading && (
            <div className="d-flex justify-content-center">
              <Oval width={30} height={30} color="#344b7b" visible={loading} />
            </div>
          )}
          <AjoutUtilisateur
            data={infoUser}
            setPageActive={setPageActive}
            afficherListeUtilisateur={afficherListeUtilisateur}
          />
          <UpdateProfilUtilisateur
            data={infoUser}
            setPageActive={setPageActive}
            afficherListeUtilisateur={afficherListeUtilisateur}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Utilisateurs;
