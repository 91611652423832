import React, { useState } from 'react'
import { isAuthenticated } from '../TokenValidation'
import * as yup from "yup";
import { Navigate } from 'react-router';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { enregistrerprestataire } from '../services/prestataires/prestatairesRequest';
import { registeruserservice } from '../services/prestataires/service';
import { signin } from '../services/login/LoginRequest';
import Swal from 'sweetalert2';

function Inscription() {

    const [errorAuthentication, seterrorAuthentication] = useState("");
    const [loading, setloading] = useState(false);

    const initialValues = {
        nom :"",
        responsable : "",
        numero : "",
        email : "",
        etat : 100
    };

    const validationValuesSchema = yup.object().shape({
        nom : yup.string().required("Veuillez saisir le nom"),
        responsable : yup.string().required("Veuillez saisir le nom du responsable") ,
        numero : yup.number("Veuillez saisir un numéro valide").required("Veuillez saisir un numéro"),
        email : yup.string().email("Veuillez saisir un e-mail valide").required("Veuillez saisir un email")
        
    });

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success mx-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const handleOnSubmit = (values) =>{
        setloading(true);
        enregistrerprestataire(values).then((response)=>{
            seterrorAuthentication("");
            registeruserservice({
                id:values.email,
                nom: values.nom,
                prenoms: values.nom,
                numero: values.numero,
                email: values.email,
                fonction: "Administrateur",
                prestataire_id: response.data.donnee.id
            }).then((res)=>{
                setloading(false);
                swalWithBootstrapButtons.fire(
                    'Enregistrer!',
                    'Vérifiez votre boîte mail pour récupérer vos identifiants de connexion.',
                    'success'
                );
                document.getElementById("seconnecter").click();
            }).catch((er)=>{
                setloading(false);
                console.log(er.response.data);
            });

        }).catch((error)=>{
            setloading(false);
            console.log(error.response.data);
            typeof error.response.data.donnee === "string" && seterrorAuthentication(error.response.data.donnee)
        });
    };


  return (
    <div className=' p-4 d-flex justify-content-center' style={{height : "100vh", backgroundColor:"#E5E5E5"}}>
    {
      isAuthenticated() && <Navigate to="/dashboard" />
    }
    <div style={{marginTop :"5%"}} className="w-50 shadow bg-white rounded px-5 py-2 h-90 ">
      <div >
        <div className='mx-3 text-center'>
          <img width={150} className='img-fluid' src="/images/LogoSKANnPayBleu.png" alt="Logo SKANPAY" />
        </div>
        <div>
            <h3 className='text-center'>Inscription prestataire</h3>
        </div>
        
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationValuesSchema}
        onSubmit={handleOnSubmit}
      >
        <Form>

          <p className='alert-danger'>{errorAuthentication}</p>
          <div className='mb-3'>
            <label htmlFor="nom" className='form-label fw-bold'>Entreprise</label>
            <Field type="text" className='form-control' id='nom' name='nom' />
            <p className='text-danger'><ErrorMessage name='nom'></ErrorMessage></p>
          </div>
          <div className='mb-3'>
            <label htmlFor="responsable" className='form-label fw-bold'>Responsable</label>
            <Field type="text" className='form-control' id='responsable' name='responsable' />
            <p className='text-danger'><ErrorMessage name='responsable'></ErrorMessage></p>
          </div>
          <div className='mb-3'>
            <label htmlFor="numero" className='form-label fw-bold'>Numéro</label>
            <Field type="text" className='form-control' id='numero' name='numero' />
            <p className='text-danger'><ErrorMessage name='numero'></ErrorMessage></p>
          </div>
          <div className='mb-3'>
            <label htmlFor="email" className='form-label fw-bold'>E-mail</label>
            <Field type="email" className='form-control' id='email' name='email' />
            <p className='text-danger'><ErrorMessage name='email'></ErrorMessage></p>
          </div>
          <div className='d-flex justify-content-between'>
            <Oval width={30} height={30} color="#344b7b" visible={loading} />
            {!loading && <button type='submit' className='btn text-white blueSkanpay'>S'inscrire</button>}
            <Link to="/login" id='seconnecter' className='btn btn-link'>Se connecter</Link>

          </div>
        </Form>
      </Formik>

    </div>
  </div>
  )
}

export default Inscription