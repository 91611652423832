import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const enregistrerservice = async(values)=>{
    return await axios.post(baseUrl("enregistrerservice"),values,headers);
}; 

export const rechercherservices = async()=>{
    return await axios.get(baseUrl("rechercherservices"), headers);
};

export const rechercherallservices = async()=>{
    return await axios.get(baseUrl("rechercherallservices"), headers);
}

export const registeruserservice = async(values)=>{
    return await axios.post(baseUrl("registerService"),values, headers);
};

export const updateuserservice = async(values)=>{
    return await axios.post(baseUrl("updateUserService"),values, headers);
};

export const rechercherserviceparprestataire = async(id)=>{
    return await axios.get(baseUrl("rechercherserviceparprestataire/"+id),headers);
}
