import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import { rechercherprestataireparid, rechercherutilisateurparprestataire } from '../../services/prestataires/prestatairesRequest';
import { rechercherpestataireservice, rechercherserviceparprestataire } from '../../services/prestataires/service';
import { changeStatut } from '../../services/utilisateurs/UtilisateursRequest';
import AjoutService from './AjoutService';
import AjoutUtilisateur from './AjoutUtilisateur';
import { Oval } from 'react-loader-spinner';
import DetailCommission from '../services-prestataire/DetailCommission';


function DetailsClients() {
    const [listeServices, setlisteServices] = useState([]);
    const [listeUtilisateur, setlisteUtilisateur] = useState([]);
    const [infoUser, setinfoUser] = useState({})
    let { idPrestataire } = useParams();
    const [infoServiceSelectionner, setinfoServiceSelectionner] = useState({});
    const [infoPrestataire, setinfoPrestataire] = useState({});
    const [ongletActif, setongletActif] = useState({
        service: true,
        utilisateur: false
    });
    const [loading, setloading] = useState(false);

    const hangleOngletActif = (val = 1) => {
        if (val === 1) {
            setongletActif({
                service: true,
                utilisateur: false
            });

        }
        else {
            setongletActif({
                service: false,
                utilisateur: true
            });
        }
    };

    //On récupère les informations du prestataire
    const recuperePrestataire = () => {
        rechercherprestataireparid(idPrestataire).then((res) => {
            setinfoPrestataire(res.data.donnee)
        })
    }

    //Affichage de la liste des services du prestataire
    const afficherService = () => {
        setloading(true)
        rechercherserviceparprestataire(idPrestataire).then((res) => {
            setloading(false);
            setlisteServices(res.data.donnee);
        }).catch((error) => {
            setloading(false);
        })
    }

    //Affichage de la liste des utilisateurs du prestataire
    const afficherUtilisateurs = () => {
        setloading(true);
        rechercherutilisateurparprestataire(idPrestataire).then((res) => {
            console.log(res.data.donnee);
            setlisteUtilisateur(res.data.donnee);
            setloading(false);
        }).catch((error) => {
            setloading(false);
        });
    }

    //Changement d'état de l'utilisateur
    const handleStatut = ($id) => {
        changeStatut($id).then((res) => {
            afficherUtilisateurs();
        })
    }

    useEffect(() => {
        hangleOngletActif();
        recuperePrestataire();
        afficherService();
        afficherUtilisateurs();
    }, []);



    const rowListService = listeServices.map((item) => (
        <tr key={item.id} >
            <td >
                {item.designation}
            </td>
            <td >
                {item.urlNotification}
            </td>
            <td>{item.description}</td>
            <td width="20%" className='text-center'>
                <button onClick={() => setinfoServiceSelectionner(item)} data-bs-target="#detailCommission" data-bs-toggle="modal" className='btn btn-sm btn-dark'><i className='fa fa-eye'></i></button>
            </td>
            <td>
                <button onClick={() => setinfoServiceSelectionner(item)} data-bs-target="#ajoutService" data-bs-toggle="modal" className='btn btn-sm'><i className='fa fa-pencil-alt'></i></button>
            </td>
        </tr>
    ));

    const rowListeUtilisateur = listeUtilisateur.map((item) => (
        <tr key={item.id} onClick={()=>setinfoUser(item)}>
           
            <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">{item.nom}</td>
            <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">{item.prenoms}</td>
            <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
                {item.id}
            </td>
            <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
              {item.email}
            </td>
            <td data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur">
                {item.numero}
            </td>
            <td onClick={() => handleStatut(item.id)}> {item.enabled ? <a className='btn text-success'><i className='fa fa-sign-in-alt'></i> Actif</a> : <a type='button' className='btn text-danger'> <i className='fa fa-ban'></i> Inactif</a>} </td>

        </tr>
    ));

    return <Layout>
        <Link to="/prestataires" className='fw-bold' replace> <i className='fa fa-arrow-left'></i> Retour</Link>
        <div className='my-3 text-center'>
            <h3 className='colorBlueTextSkanPay fw-bold'>{infoPrestataire.nom} </h3>
        </div>

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={ongletActif.service ? "nav-link active text-white" : "nav-link"} onClick={() => hangleOngletActif(1)}>Services</a>
            </li>
            <li className="nav-item">
                <a className={ongletActif.utilisateur ? "nav-link active text-white" : "nav-link"} onClick={() => hangleOngletActif(2)}>Utilisateurs</a>
            </li>
        </ul>

        {
            //Service
            ongletActif.service && <div className='mt-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>
                        <legend>Services</legend>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3'>
                        </div>
                        <div>
                            <a type='button' onClick={() => setinfoServiceSelectionner({})} data-bs-toggle="modal" data-bs-target="#ajoutService" className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau service</a>
                            <AjoutService afficherService={afficherService} prestataireId={idPrestataire} datas={infoServiceSelectionner} />
                        </div>

                    </div>
                </div>
                <table className="ui celled padded table">
                    <thead>
                        <tr><th >Désignation</th>
                            <th>URL de notification</th>
                            <th>description</th>
                            <th>Commissions appliquées</th>
                            <th width="15%">Action rapide</th>
                        </tr></thead>
                    <tbody>
                        {rowListService}
                    </tbody>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
                <DetailCommission data={infoServiceSelectionner} />
            </div>
        }

        {
            //Utilisateurs
            ongletActif.utilisateur && <div className='mt-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>
                        <legend>Utilisateurs</legend>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3'>

                        </div>
                        <div>
                            <a type='button' onClick={()=>setinfoUser({})} data-bs-toggle="modal" data-bs-target="#ajoutUtilisateur" className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouvel utilisateur</a>
                            <AjoutUtilisateur datas={infoUser} afficherUtilisateurs={afficherUtilisateurs} listeServices={listeServices} prestataireId={idPrestataire} />
                        </div>

                    </div>
                </div>
                <table className="ui celled table-hover padded table">
                    <thead>
                        <tr>
                            <th >Nom</th>
                            <th>Prénoms</th>
                            <th>Login</th>
                            <th>Email</th>
                            <th>Numéro</th>
                            <th>Statut</th>
                        </tr></thead>
                    <tbody>

                        {rowListeUtilisateur}
                    </tbody>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
            </div>
        }

    </Layout>
}

export default DetailsClients;
