import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { convertNumber } from '../../convertToMoneyFormat';
import Layout from '../../layouts/Layout'
import { rechercheroperateurparpays } from '../../services/parametres/operateur';
import { rechercherpays } from '../../services/parametres/pays';
import { rechercherreversementparoperateurs, statsreversementops } from '../../services/reversement/reversementRequest';
import Paginate from '../pagination'
import AjoutReversementOps from './AjoutReversementOps';

function ReversementOperateur() {
    const [totalElement, settotalElement] = useState(0);
    const [totalElementByPage, setTotalElementByPage] = useState(0);
    const [pageActive, setPageActive] = useState(1);
    const [loading, setloading] = useState(false)
    const [listePays, setlistePays] = useState([]);
    const [paysSelected, setpaysSelected] = useState("");
    const [operateurSelected, setoperateurSelected] = useState("");
    const [listeOperateur, setlisteOperateur] = useState([]);
    const [listeReversements, setlisteReversements] = useState([]);
    let [paramFilter, setparamFilter] = useState({
        pays_id: "",
        operateur_id: ""
    });
    const [infoMontant, setinfoMontant] = useState([]);


    const handlePageChange = (pageNumber) => {
        setPageActive(pageNumber);
        afficherListeReversement(pageNumber);
    }

    const afficherListePays = () => {
        rechercherpays().then((res) => {
            setlistePays(res.data.donnee);
        })
    };

    const afficherOperateurs = (id) => {
        rechercheroperateurparpays(id).then((res) => {
            setlisteOperateur(res.data.donnee);
        })
    };

    const handlePays = (idpays) => {
        paramFilter.pays_id = idpays;
        paramFilter.operateur_id = "";
        setparamFilter(paramFilter);
        statsreversementops(paramFilter).then((res)=>{
            setinfoMontant(res.data.donnee);
        });
        setpaysSelected(idpays);
        setoperateurSelected("");
        (idpays !== "") ? afficherOperateurs(idpays) : setlisteOperateur([]);
        afficherListeReversement(1);
    };

    const handleOperateur = (idOperateur) =>{
        paramFilter.operateur_id = idOperateur;
        setparamFilter(paramFilter);
        statsreversementops(paramFilter).then((res)=>{
            setinfoMontant(res.data.donnee);
        });
        setoperateurSelected(idOperateur);
        afficherListeReversement(1);
    }

    const afficherListeReversement = (currentPage) =>{
        rechercherreversementparoperateurs(currentPage, paramFilter).then((res)=>{
            setlisteReversements(res.data.donnee.data);
            settotalElement(res.data.donnee.total);
            setTotalElementByPage(res.data.donnee.per_page);

            //Actualiser les stats
            statsreversementops(paramFilter).then((res)=>{
                setinfoMontant(res.data.donnee);
            });
        }).catch((error)=>{
            console.log(error.response);
        })
    }


    const optionListPays = listePays.map((item) => (
        <option key={item.code} value={item.id}>{item.designation}</option>
    ));

    const optionListOperateur = listeOperateur.map((item, index) => (
        <option key={index} value={item.id}>{item.designation} | {item.id}</option>
    ));

    const rowListeReversement = listeReversements.map((item, index)=>(
        <tr key={index}>
            <td>{item.operateur_id}</td>
            <td>{item.montant}</td>
            <td>{(item.dateVersement !== undefined) && moment(item.dateVersement).format("DD-MM-yyyy")}</td>
        </tr>
    ))

    useEffect(() => {
        afficherListePays();
        afficherListeReversement(pageActive);
    }, [])

    return (
        <Layout>
            <div>
                <div className='container'>
                    <div className='row'>
                        <div className="col-sm-3 mt-2">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h5 className="card-title fw-bold">Montant des transactions </h5>
                                    <p className="card-text fw-bold fs-5 colorBlueTextSkanPay"> {(infoMontant.montantTransaction !== undefined) && convertNumber(infoMontant.montantTransaction)} FCFA </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 mt-2">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h5 className="card-title fw-bold ">Montant reverser </h5>
                                    <p className="card-text fw-bold fs-5 colorBlueTextSkanPay"> {(infoMontant.montantReversement !== undefined) && convertNumber(infoMontant.montantReversement)} FCFA  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-5'>
                        <div className='fw-bold'>
                            <legend>Reversements opérateurs</legend>
                        </div>
                        <div className='d-flex'>
                            <div className='d-flex mx-3'>
                                <div className='mb-3'>
                                    <select onChange={(e) => handlePays(e.target.value)} value={paysSelected} name="pays_id" className='form-select' id="pays_id">
                                        <option value="">Veuillez sélectionner le pays</option>
                                        {optionListPays}
                                    </select>
                                </div>
                                <div className='mb-3 mx-2'>
                                    <select onChange={(e)=>handleOperateur(e.target.value)} value={operateurSelected} name="operateur_id" className='form-select' id="operateur_id">
                                        <option value="">Veuillez sélectionner l'opérateur</option>
                                        {optionListOperateur}
                                    </select>
                                </div>
                            </div>
                            <div>
                                <a type='button' data-bs-toggle="modal" data-bs-target="#ajoutReversementoperateur" className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau reversement</a>
                                <AjoutReversementOps afficherListeReversement={afficherListeReversement} pageActive={pageActive} />
                            </div>

                        </div>
                    </div>
                    <table className="ui celled padded table">
                        <thead>
                            <tr>
                                <th width="30%">Opérateur</th>
                                <th>Montant</th>
                                <th width="15%">Date de versement</th>
                            </tr></thead>
                        <tbody>
                            {rowListeReversement}
                        </tbody>
                        <tfoot>
                            <tr><th colSpan="6">
                                <div className='d-flex justify-content-center'>
                                    <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
                                </div>
                            </th>
                            </tr></tfoot>
                    </table>
                    {loading &&
                        <div className='d-flex justify-content-center'>
                            <Oval width={30} height={30} color="#344b7b" visible={loading} />
                        </div>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default ReversementOperateur