import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const enregistrerreversement = async(values)=>{
    return await axios.post(baseUrl("enregisterreversementservice"),values,headers);
};

export const rechercherreversementparservice = async(params, page)=>{
    return await axios.post(baseUrl("rechercherreversementparservice?page="+page), params, headers);
};

export const rechercherreversementparprestataire = async(values, page)=>{
    return await axios.post(baseUrl("rechercherreversementparprestataire?page="+page),values,headers);
};

//Reversement opérateur

export const statsreversementops = async(values)=>{
    return await axios.post(baseUrl("statsreversementops"),values,headers);
};

export const enregistrerreversementoperateur = async(values)=>{
    return await axios.post(baseUrl("enregistrerreversementoperateur"),values,headers);
};

export const rechercherreversementparoperateurs = async(page, values)=>{
    return await axios.post(baseUrl("rechercherreversementparoperateurs?page="+page), values, headers);

}

