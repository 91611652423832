import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const rechercherprestataires = async(page) =>{
    return await axios.get(baseUrl("rechercherprestataires?page="+page),headers);
};

export const enregistrerprestataire = async(values)=>{
    return await axios.post(baseUrl("enregistrerprestataire"),values,headers);
};

export const changeretatprestataire = async(id)=>{
    return await axios.get(baseUrl("changeretatprestataire/"+id),headers);
}

//Retourne les infos insformations d'un prestataire
export const rechercherprestataireparid = async(id)=>{
    return await axios.post(baseUrl("rechercherprestataireparid/"+id),{},headers);
}

export const rechercherutilisateurparprestataire = async(id) =>{
    return await axios.get(baseUrl("rechercherutilisateurparprestataire/"+id),headers);
};

export const rechercherprestataire = async(page, param) =>{
    return await axios.post(baseUrl("rechercherprestataires?page="+page),param,headers);
};

export const rechercherallprestataires = async() =>{
    return await axios.get(baseUrl("rechercherallprestataires"),headers);
};


