import { Form, Formik, Field, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as yup from 'yup'
import { reinitialiserpassword } from '../services/login/ResetPasswordRequest';
import $ from 'jquery';
import { Oval } from 'react-loader-spinner';


function MotdepasseOublie() {

    let [messageErrorReinitialisation, setmessageErrorReinitialisation] = useState("");
    const [loading, setloading] = useState(false)
    const initialValues = {
        username: ""
    }

    const validationSchemaValues = yup.object().shape({
        username: yup.string().email("Veuillez entrer un email valide svp").required("Veuillez entrer un email")
    });



    const handleOnsubmitReinitialize = (values) => {

        Swal.fire({
            title: 'Etes-vous sûr de bien vouloir réinitialiser votre mot de passe?',
            text: "Cette action est irreversible !",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonColor: '#344b7b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, réinitialiser'
        }).then((result) => {

            if (result.isConfirmed) {
                setloading(true)
                reinitialiserpassword(values).then((res) => {
                    setloading(false)
                    Swal.fire(
                        'Récupérer votre mot de passe!',
                        'Un email contenant votre nouveau mot de passe vous a été envoyé',
                        'success'
                    );
                    setmessageErrorReinitialisation("");

                    $('#fermer').trigger('click');
                }).catch((err) => {
                    setloading(false);
                    setmessageErrorReinitialisation(err.response.data.donnee);
                    console.log(err.response.data);
                })

            }

        })
    }

    return <div className="modal fade" id="mdpOublie" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header blueSkanpay text-white fw-bold">
                    <h5 className="modal-title h4" id="exampleModalLabel">Réinitialiser votre mot de passe</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaValues}
                    onSubmit={handleOnsubmitReinitialize}
                >
                    {({ resetForm }) => (
                        <Form>
                            <div className="modal-body">
                                <p className='text-danger'>{messageErrorReinitialisation}</p>
                                <div className='mb-3'>
                                    <label htmlFor="username" className='form-label'>Adresse email</label>
                                    <Field type="text" name='username' placeholder="Ex:contact@skanci.com" id="username" className='form-control' />
                                    <p className='text-danger'><ErrorMessage name='username'></ErrorMessage></p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={resetForm} id="fermer" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                {!loading && <button type="submit" className="btn blueSkanpay text-white">Réinitialiser</button>}
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </div>
}

export default MotdepasseOublie;
