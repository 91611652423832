import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner';
import Layout from '../../layouts/Layout'
import { rechercherallprestataires, rechercherprestataire } from '../../services/prestataires/prestatairesRequest';
import { rechercherallservices, rechercherserviceparprestataire } from '../../services/prestataires/service';
import DateFilter from '../DateFilter';
import Paginate from '../pagination';
import moment from 'moment';
import AjoutDemandeReversement from './AjoutDemandeReversement';
import { rechercherdemandesreversement } from '../../services/demande-reversement/DemandeRequest';

function DemandeReversement() {

  const [listePrestataires, setlistePrestataires] = useState([]);
  const [listeServices, setlisteServices] = useState([]);
  const [listeDemandes, setlisteDemandes] = useState([])
  let [filterParametres, setfilterParametres] = useState({
    dateDebut: "",
    dateFin: "",
    prestataire_id: "",
    service_id: "",
    statut: ""
  });

  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [pageActive, setPageActive] = useState(1);
  const [loading, setloading] = useState(false)

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    afficherLesDemandes(pageNumber);
  }

  const afficherPrestataires = () => {
    rechercherallprestataires().then((res) => {
      setlistePrestataires(res.data.donnee);
    })
  }

  const displayAllservice = () => {
    rechercherallservices().then((res) => {
      setlisteServices(res.data.donnee);
    })
  }

  const afficherService = (id) => {
    rechercherserviceparprestataire(id).then((res) => {
      setlisteServices(res.data.donnee);
    })
  }

  //Filtrer par prestataire
  const filterOnPrestataire = (e) => {
    e.target.value !== "" ? afficherService(e.target.value) : displayAllservice();
    filterParametres.prestataire_id = e.target.value;
    filterParametres.service_id = "";
    setfilterParametres(filterParametres);
    afficherLesDemandes(pageActive)
  }

  //Filtrer par Service
  const filterOnService = (e) => {
    filterParametres.service_id = e.target.value;
    setfilterParametres(filterParametres);
    afficherLesDemandes(pageActive)
  };

  //Recherche sur la date
  const filterOnDate = (dateDebut, dateFin) => {
    filterParametres.dateDebut = (dateDebut !== null && dateDebut !== "") ? moment(dateDebut).format("yyyy-MM-DD") : "";
    filterParametres.dateFin = (dateFin !== null && dateFin !== "") ? moment(dateFin).format("yyyy-MM-DD") : "";
    setfilterParametres(filterParametres);
    afficherLesDemandes(pageActive)
  }

  const afficherLesDemandes = (currentPage) => {
    setloading(true)
    rechercherdemandesreversement(filterParametres, currentPage).then((res) => {
      setloading(false);
      setlisteDemandes(res.data.donnee.data);
      settotalElement(res.data.donnee.total);
      setTotalElementByPage(res.data.donnee.per_page);
    }).catch((err) => {
      setloading(false);

    })
  }


  useEffect(() => {
    afficherPrestataires();
    displayAllservice();
    afficherLesDemandes(pageActive);
  }, []);

  const optionPrestataire = listePrestataires.map((item) => (
    <option key={item.id} value={item.id}>{item.nom}</option>
  ));
  const optionListeService = listeServices.map((item) => (
    <option key={item.id} value={item.id}>{item.designation}</option>
  ));

  const rowListeDemandeReversement = listeDemandes.map((item, index) => (
    <tr key={index}>
      {(localStorage.getItem("role") === "ROLE_ADMIN") &&
        <td>{item.service.prestataire.nom}</td>
      }
      <td>{item.service.designation}</td>
      <td>{item.commentaire}</td>
      <td>{moment(item.created_at).format("DD-MM-yyyy H:m")}</td>
      <td>
        {item.statut ? <span className='text-success fw-bold'>Traité</span> : <span className='text-danger fw-bold'>En attente</span>}
      </td>
    </tr>
  ))

  return (
    <Layout>

      <div>
        <div className='container'>
          <div className='d-flex justify-content-between mt-5'>
            <div className='fw-bold'>
              <legend>Demandes de reversements</legend>
            </div>
            <div>
              <DateFilter filterElement={filterOnDate} />
            </div>
            <div className='d-flex'>
              <div className='d-flex '>
                {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                  <div className='mx-2'>
                    <select onChange={(e) => filterOnPrestataire(e)} value={filterParametres.prestataire_id} className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option value="">Sélectionner le prestataire</option>
                      {optionPrestataire}
                    </select>
                  </div>
                }
                <div className='mx-1'>
                  <select onChange={(e) => filterOnService(e)} value={filterParametres.service_id} className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value="">Sélectionner le service</option>
                    {optionListeService}
                  </select>
                </div>
              </div>

              {(localStorage.getItem("role") === "ROLE_ADMIN_PRESTATAIRE") &&
                <div>
                  <a type='button' data-bs-toggle="modal" data-bs-target="#ajoutDemande" className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouvelle demande</a>
                  <AjoutDemandeReversement afficherLesDemandes={afficherLesDemandes} />
                </div>
              }
            </div>
          </div>
          <table className="ui celled padded table">
            <thead>
              <tr>
                {(localStorage.getItem("role") === "ROLE_ADMIN") &&
                  <th className="single line">Prestataire</th>
                }
                <th>Service</th>
                <th>Commentaire</th>
                <th>Date</th>
                <th>Statut</th>
              </tr></thead>
            <tbody>
              {rowListeDemandeReversement}
            </tbody>
            <tfoot>
              <tr><th colSpan="6">
                <div className='d-flex justify-content-center'>
                  <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
                </div>
              </th>
              </tr></tfoot>
          </table>
          {loading &&
            <div className='d-flex justify-content-center'>
              <Oval width={30} height={30} color="#344b7b" visible={loading} />
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default DemandeReversement