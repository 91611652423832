import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const rechercherpaiements = async (pages) => {
  return await axios.get(baseUrl("rechercherpaiements?page=" + pages), headers);
};

export const rechercherpaiement = async (values, page) => {
  return await axios.post(
    baseUrl("rechercherpaiements?page=" + page),
    values,
    headers
  );
};

export const recherchernombrepaiement = async (values, page = "") => {
  return await axios.post(
    baseUrl("recherchermontantpaiementparserviceparoperateur?page=" + page),
    values,
    headers
  );
};

export const recherchernombreetmontantpaiement = async (values, page = "") => {
  return await axios.post(
    baseUrl("recherchernombreetmontanttotaltransactions?page=" + page),
    values,
    headers
  );
};

export const rechercherpaiementsparprestataire = async (params, page) => {
  return await axios.post(
    baseUrl("rechercherpaiements?page=" + page),
    params,
    headers
  );
};

export const statspaiementsprestataire = async (params) => {
  return await axios.post(
    baseUrl("statspaiementsprestataire"),
    params,
    headers
  );
};
