import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const getAllUser = async(numberPage, values) =>{
    return await axios.post(baseUrl("rechercherutilisateurs?page="+numberPage), values, headers);
};

export const changeStatut = async(id) =>{
    return await axios.get(baseUrl("changeretatutilisateur/"+id),headers);
};

export const registeruser = async(values) =>{
    return await axios.post(baseUrl("register"),values,headers);
};
export const updateuser = async(values) =>{
    return await axios.post(baseUrl("updateuser"),values,headers);
};

export const currentuserconnected = async()=>{
    return await axios.get(baseUrl("currentUser"),headers);
};

export const profilUserConnected = async()=>{
    return await axios.get(baseUrl("profilUserConnected"),headers);
};

export const updateProfilUtilisateur = async(values)=>{
    return await axios.post(baseUrl("modifierProfil"), values ,headers);
};

export const chercherutilisateur = async(values, page)=>{
    return await axios.post(baseUrl("rechercherutilisateur?page="+page),values,headers);
}
