import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { rechecherroles } from '../../services/roles';
import Multiselect from 'multiselect-react-dropdown';
import { registeruser, updateuser } from '../../services/utilisateurs/UtilisateursRequest';
import $ from 'jquery'
import { rechercherallprestataires, rechercherprestataire } from '../../services/prestataires/prestatairesRequest';
import { rechercherallservices, rechercherpestataireservice, rechercherserviceparprestataire, registeruserservice, updateuserservice } from '../../services/prestataires/service';
import { Autocomplete, TextField } from '@mui/material';
import { Oval } from 'react-loader-spinner';

function AjoutUtilisateur(props) {


    const [listeRoles, setlisteRoles] = useState([]);
    const [messageError, setmessageError] = useState("");
    const [visibleTagEntreprise, setvisibleTagEntreprise] = useState(false);
    const [visibleTagService, setvisibleTagService] = useState(false);
    const [visibleTagReceive, setvisibleTagReceive] = useState(false);
    const [listePrestataire, setlistePrestataire] = useState([]);
    const [listeService, setlisteService] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [prestataireSelected, setprestataireSelected] = useState("");
    const [loading, setloading] = useState(false);
    const [recevoirDemande, setrecevoirDemande] = useState(false);

    let initialValues = {
        id: props.data.id !== undefined ? props.data.id : "",
        nom: props.data.nom !== undefined ? props.data.nom : "",
        prestataire_id: "",
        prenoms: props.data.prenoms !== undefined ? props.data.prenoms : "",
        numero: props.data.numero !== undefined ? props.data.numero : "",
        email: props.data.email !== undefined ? props.data.email : "",
        password: props.data.password !== undefined ? props.data.password : "",
        fonction: props.data.fonction !== undefined ? props.data.fonction : "",
        role: [],
        recevoirDemandeReversement: props.data.recevoirDemandeReversement !== undefined ? props.data.recevoirDemandeReversement : false
    };

    const validationValuesSchema = yup.object().shape({
        nom: yup.string().required("Veuillez entrer le nom"),
        prenoms: yup.string().required("Veuillez entrer les prénoms"),
        numero: yup.string().required("Veuillez entrer un numéro de téléphone"),
        id: yup.string().required("Veuillez entrer le login"),
        email: yup.string().required("Veuillez entrer l'e-mail").email("Veuillez entrer un e-mail valide"),
        password: yup.string().min(4, "Veuillez entrer au moins 4 caractères"),
        fonction: yup.string().required("Veuillez entrer la fonction")
    })

    const isAdminAddUser = (values) =>{
        props.data.id !== undefined ?
        updateuser(values).then((res) => {
            setloading(false)
            props.afficherListeUtilisateur(1);
            setSelectedValue([]);
            setvisibleTagEntreprise(false);
            setvisibleTagService(false);
            setmessageError("");
            props.setPageActive(1);
            setprestataireSelected("");
            document.getElementById('closeAddUser').click();
        }).catch((err) => {
            console.log(err.response.data);
            err.response.data.donnee.role !== undefined && setmessageError("Veuillez sélectionner au moins un rôle");
            ((typeof err.response.data.donnee) === "string") && setmessageError(err.response.data.donnee);
            setloading(false);

        })
        :
        registeruser(values).then((res) => {
            setloading(false)
            props.afficherListeUtilisateur(1);
            setSelectedValue([]);
            setvisibleTagEntreprise(false);
            setvisibleTagService(false);
            setmessageError("");
            props.setPageActive(1);
            setprestataireSelected("");
            document.getElementById('closeAddUser').click();
        }).catch((err) => {
            console.log(err.response.data);
            err.response.data.donnee.role !== undefined && setmessageError("Veuillez sélectionner au moins un rôle");
            ((typeof err.response.data.donnee) === "string") && setmessageError(err.response.data.donnee);
            setloading(false);

        });
    }

    const isAdminPrestataireAddUser = (values) =>{
        values.prestataire_id = localStorage.getItem("prestataires");
        props.data.id !== undefined ?
        updateuserservice(values).then((res) => {
            setloading(false)
            props.afficherListeUtilisateur(1);
            setSelectedValue([]);
            setvisibleTagEntreprise(false);
            setvisibleTagService(false);
            setmessageError("");
            props.setPageActive(1);
            setprestataireSelected("");
            document.getElementById('closeAddUser').click();
        }).catch((err) => {
            console.log(err.response.data);
            err.response.data.donnee.role !== undefined && setmessageError("Veuillez sélectionner au moins un rôle");
            ((typeof err.response.data.donnee) === "string") && setmessageError(err.response.data.donnee);
            setloading(false);

        })
        :
        registeruserservice(values).then((res) => {
            setloading(false)
            props.afficherListeUtilisateur(1);
            setSelectedValue([]);
            setvisibleTagEntreprise(false);
            setvisibleTagService(false);
            setmessageError("");
            props.setPageActive(1);
            setprestataireSelected("");
            document.getElementById('closeAddUser').click();
        }).catch((err) => {
            console.log(err.response.data);
            err.response.data.donnee.role !== undefined && setmessageError("Veuillez sélectionner au moins un rôle");
            ((typeof err.response.data.donnee) === "string") && setmessageError(err.response.data.donnee);
            setloading(false);

        });
    }

    //Enregistrement de l'utilisateur 
    const handleOnsubmitUtilisateur = (values) => {
        !visibleTagEntreprise ? (values.prestataire_id = "") : (values.prestataire_id = prestataireSelected);

        let roleuser = Array()

        selectedValue.forEach(element => {
            roleuser.push(element.id);
        });
        values.role = roleuser;
        visibleTagReceive ? (values.recevoirDemandeReversement = recevoirDemande) : (values.recevoirDemandeReversement = false);
        setloading(true);

        if(localStorage.getItem("role") === "ROLE_ADMIN"){
            isAdminAddUser(values);
        }else{
            isAdminPrestataireAddUser(values);
        }
    }

    //Requête pour récupérer la liste des rôles
    const getListeRoles = () => {
        rechecherroles().then((res) => {
            setlisteRoles(res.data.donnee);
        })
    }

    //Requête pour récuper la liste des prestataire
    const getListPrestataire = () => {
        rechercherallprestataires().then((res) => {
            setlistePrestataire(res.data.donnee);
        })
    };

    //Requête pour récuper la liste des service du prestataire
    const getListService = (id) => {
        rechercherserviceparprestataire(id).then((res) => {
            setlisteService(res.data.donnee);
        })
    };

    useEffect(() => {
        getListeRoles();
        prestataireSelected !== "" && getListService(prestataireSelected);
        getListPrestataire();
    }, []);

    useEffect(() => {
        if (props.data.recevoirDemandeReversement !== undefined) {
            setvisibleTagReceive(props.data.recevoirDemandeReversement);
            setrecevoirDemande(props.data.recevoirDemandeReversement);
        }
    }, [props.data.recevoirDemandeReversement])

    //Option liste prestataire
    const optionListePrestataire = listePrestataire.map((item) => (
        <option key={item.id} value={item.id}>{item.nom}</option>
    ));

    //Option liste service
    const optionListeService = listeService.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));


    return <div className="modal fade" id="ajoutUtilisateur" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">UTILISATEUR</h5>
                    <button type="button" onClick={() => { setSelectedValue([]); setmessageError("") }} className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationValuesSchema}
                        onSubmit={handleOnsubmitUtilisateur}
                    >
                        {({ resetForm }) => (
                            <Form>
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}
                                <div className="mb-3">
                                    <label htmlFor="nom" className="form-label">Nom</label>
                                    <Field type="text" name='nom' className="form-control" id="nom" />
                                    <p className='text-danger'><ErrorMessage name='nom'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="prenoms" className="form-label">Prénoms</label>
                                    <Field type="text" name='prenoms' className="form-control" id="prenoms" />
                                    <p className='text-danger'><ErrorMessage name='prenoms'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="numero" className="form-label">Numéro de téléphone</label>
                                    <Field type="text" name='numero' className="form-control" id="numero" />
                                    <p className='text-danger'><ErrorMessage name='numero'></ErrorMessage></p>
                                </div>

                                {props.data.id === undefined && <div className="mb-3">
                                    <label htmlFor="login" className="form-label">Login</label>
                                    <Field type="text" name='id' className="form-control" id="login" />
                                    <p className='text-danger'><ErrorMessage name='id'></ErrorMessage></p>
                                </div>
                                }

                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">E-mail</label>
                                    <Field type="text" name='email' className="form-control" id="email" />
                                    <p className='text-danger'><ErrorMessage name='email'></ErrorMessage></p>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <Field type="password" name='password' className="form-control" id="password" />
                                    <p className='text-danger'><ErrorMessage name='password'></ErrorMessage></p>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fonction" className="form-label">Fonction</label>
                                    <Field type="text" name='fonction' className="form-control" id="fonction" />
                                    <p className='text-danger'><ErrorMessage name='fonction'></ErrorMessage></p>
                                </div>

                             {localStorage.getItem("role") === "ROLE_ADMIN" && <div className="mb-3">

                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        isOptionEqualToValue={(option, value) => option.id == value.id}
                                        filterSelectedOptions
                                        value={selectedValue}
                                        onChange={(_event, newItem) => {
                                            setSelectedValue(newItem);
                                            let role = Array()

                                            newItem.forEach(element => {
                                                role.push(element.id);
                                            });

                                            if (role.length === 0) {
                                                setvisibleTagEntreprise(false);
                                                setvisibleTagReceive(false);

                                            } else {

                                                if (role.includes("ROLE_ADMIN")) {
                                                    setvisibleTagReceive(true);
                                                } else {
                                                    setvisibleTagReceive(false);
                                                }

                                                if (role.includes("ROLE_ADMIN_PRESTATAIRE")) {
                                                    setvisibleTagEntreprise(true);
                                                } else {
                                                    setvisibleTagEntreprise(false);
                                                }

                                            }
                                        }}
                                        id="listeRoleSelected"
                                        options={listeRoles}
                                        getOptionLabel={(option) => option.libelle}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" label="Sélectionner le(s) rôle(s) .. " placeholder="Roles" />
                                        )}
                                        sx={{ width: '100%' }}
                                    />

                                </div>
                                }

                                {visibleTagEntreprise && localStorage.getItem("role") === "ROLE_ADMIN" &&
                                    <div className='mb-3'> <select onChange={(e) => { setprestataireSelected(e.target.value); getListService(e.target.value) }} value={prestataireSelected} className="form-select" name="prestataires_id" id="prestataires_id">
                                        <option value="">Veuillez sélectionner le prestataire</option>
                                        {optionListePrestataire}
                                    </select>
                                    </div>
                                }

                                {((localStorage.getItem("role") === "ROLE_ADMIN") && ((visibleTagReceive === 1) || (visibleTagReceive === true))) &&
                                    <div className='mb-3'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" onChange={() => setrecevoirDemande(!recevoirDemande)} checked={recevoirDemande} name="isAdminPrestataire" type="checkbox" id="isAdminPrestataire" />
                                            <label className="form-check-label" htmlFor="isAdminPrestataire">Recevoir les demandes de reversements</label>
                                        </div>
                                    </div>
                                }

                                <div className="modal-footer bg-white">
                                    <button id='closeAddUser' type="button" onClick={() => { resetForm(); setmessageError(""); setSelectedValue([]); }} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm btn-primary">Enregistrer</button>}
                                </div>
                            </Form>

                        )}
                    </Formik>

                </div>
            </div>
        </div>
    </div>
}

export default AjoutUtilisateur;
