import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup'
import { enregistrercommission } from '../../services/parametres/commission';
import { rechercheroperateurparpays } from '../../services/parametres/operateur';
import { rechercherpays } from '../../services/parametres/pays';

function AjoutCommission(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const [listePays, setlistePays] = useState([])
    const [listeOperateur, setlisteOperateur] = useState([])
    const [isCommissionDefault, setisCommissionDefault] = useState(false)
    let [paysSelected, setpaysSelected] = useState("");

    let initialValues = {
        id : props.data.id !== undefined ? props.data.id : "",
        pays_id: (props.data.pays !== null && props.data.pays !== undefined ) ? props.data.pays.id : "",
        operateurMobileMoney_id: props.data.operateurMobileMoney_id !== undefined ? props.data.operateurMobileMoney_id : "",
        defaut: props.data.defaut !== undefined ? props.data.defaut : false,
        typeCommission: props.data.typeCommission !== undefined ? props.data.typeCommission : "",
        valeur: props.data.valeur !== undefined ? props.data.valeur : ""

    }

    const validationValuesSchema = yup.object().shape({
        typeCommission: yup.string().required("Veuillez sélectioner le type de commission"),
        valeur: yup.number().required("Veuillez saisir la commission")
        })

    const afficherListePays = () => {
        rechercherpays().then((res) => {
            setlistePays(res.data.donnee);
        })
    }

    const optionListOperateur = listeOperateur.map((item, index) => (
        <option key={index} value={item.id}>{item.designation} | {item.id}</option>
    ));

    const optionListPays = listePays.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));

    const handleOnchangeIsDefault = () => {
        setisCommissionDefault(!isCommissionDefault);
    }

    const afficherOperateurs = (id) => {
        rechercheroperateurparpays(id).then((res) => {
            setlisteOperateur(res.data.donnee);
        })
    }

    const handlePays = (value) => {
        props.data.defaut !== undefined && setisCommissionDefault(props.data.defaut);
        paysSelected = value;
        setpaysSelected(paysSelected);
        value !== "" ? afficherOperateurs(value) : setlisteOperateur([]);
    }


    useEffect(() => {
        afficherListePays();
    }, []);

    useEffect(() => {
        (props.data.pays !== null && props.data.pays !== undefined) && handlePays(props.data.pays.id)
    }, [props.data.pays])

    

    const handleOnSubmitCommission = (values) => {
       setloading(true)
        values.defaut = isCommissionDefault;
        values.pays_id = paysSelected
        if (isCommissionDefault) {
            values.operateurMobileMoney_id = "";
        }
        enregistrercommission(values).then((res)=>{
            setloading(false);
            props.affichercommission();
            document.getElementById("fermer").click();
        }).catch((error)=>{
            setloading(false);
            console.log(error.response.data);
            error.response.data.donnee.pays_id !== undefined && setmessageError("Veuillez sélectionner le pays");
            error.response.data.donnee.operateurMobileMoney_id !== undefined && setmessageError(error.response.data.donnee.operateurMobileMoney_id);
            ((typeof error.response.data.donnee ) === "string") && setmessageError(error.response.data.donnee);
        })
    }

    return (
        <div className="modal fade" id="ajoutcommission" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content blueSkanpay">
                    <div className="modal-header text-white">
                        <h5 className="modal-title" id="staticBackdropLabel">Configuration commission</h5>
                        <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationValuesSchema}
                        onSubmit={handleOnSubmitCommission}
                    >
                        {({ resetForm }) => (

                            <Form>
                                <div className="modal-body bg-white">
                                    {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                    <div className='mb-3'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" onChange={(e) => handleOnchangeIsDefault(e)} checked={isCommissionDefault} name="isDefaultCommission" type="checkbox" id="isDefaultCommission" />
                                            <label className="form-check-label" htmlFor="isDefaultCommission">Commission par Defaut</label>
                                        </div>
                                    </div>
                                            <div className='mb-3'>
                                                <label htmlFor="pays_id" className='form-label'>Pays</label>
                                                <Field component="select" onChange={(e)=>handlePays(e.target.value)} value={paysSelected} name="pays_id" className='form-select' id="pays_id">
                                                    <option value="">Veuillez sélectionner le pays</option>
                                                    {optionListPays}
                                                </Field>
                                            </div>
                                    {
                                        !isCommissionDefault && 
                                            <div className='mb-3'>
                                                <label htmlFor="operateur_mobile_money_id" className='form-label'>Opérateur</label>
                                                <Field component="select" name="operateurMobileMoney_id" className='form-select' id="operateur_mobile_money_id">
                                                    <option value="">Veuillez sélectionner l'opérateur</option>
                                                    {optionListOperateur}
                                                </Field>
                                                <p className='text-danger'><ErrorMessage name="operateurMobileMoney_id"></ErrorMessage></p>
                                            </div>
                                        
                                    }

                                    <div className='mb-3'>
                                        <label htmlFor="typeCommission" className='form-label'>Type de commission</label>
                                        <Field component="select" className='form-control' id='typeCommission' name="typeCommission" >
                                            <option value="">Sélectionner le type de commission</option>
                                            <option value="FIXE">Fixe</option>
                                            <option value="POURCENTAGE"> Pourcentage </option>
                                        </Field>
                                        <p className='text-danger'><ErrorMessage name="typeCommission"></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor="valeur" className='form-label'>Valeur</label>
                                        <Field className="form-control" name="valeur" id="valeur" type="number"/>
                                        <p className='text-danger'><ErrorMessage name="valeur"></ErrorMessage></p>
                                    </div>
                                </div>
                                <div className="modal-footer bg-white">
                                    <button type="button" id='fermer' onClick={()=>{resetForm(); setpaysSelected(""); setmessageError("")}} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    )
}

export default AjoutCommission