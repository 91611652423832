import React, { useState } from 'react';
import * as yup from 'yup'
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { enregistrerprestataire } from '../../services/prestataires/prestatairesRequest';
import $ from 'jquery';
import { Oval } from 'react-loader-spinner';

function AjoutClient(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("")
    const initialiValue = {
        id: Object.keys(props.datas).length === 0 ? "" : props.datas.id,
        nom: Object.keys(props.datas).length === 0 ? "" : props.datas.nom,
        numero: Object.keys(props.datas).length === 0 ? "" : props.datas.numero,
        responsable: Object.keys(props.datas).length === 0 ? "" : props.datas.responsable,
        email: Object.keys(props.datas).length === 0 ? "" : props.datas.email
    }
    const validationvaluesschema = yup.object().shape({
        nom: yup.string().required("Veuillez renseigner le nom du prestatire"),
        responsable: yup.string().required("Veuillez renseigner le nom du responsable"),
        numero: yup.string().required("Veuillez entrer un numéro"),
        email: yup.string().email("Veuillez renseigner un email valide").required("Veuillez renseigner un email")
    })

    const handleSubmitPrestataire = (values) => {
        setloading(true);
        enregistrerprestataire(values).then((res) => {
            setloading(false);
            props.afficherPrestataires(1);
            $('#fermer').trigger('click');
        }).catch((err) => {
            setloading(false);
            console.log(err.response.data);
           (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);
        })
    }

    return <div className="modal fade" id="ajoutClient" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">PRESTATAIRE</h5>
                    <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">
                    <Formik
                        initialValues={initialiValue}
                        onSubmit={handleSubmitPrestataire}
                        enableReinitialize
                        validationSchema={validationvaluesschema}
                    >
                        {({ resetForm }) => (

                            <Form>
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                <div className="mb-3">
                                    <label htmlFor="nom" className='form-label'>Nom prestataire</label>
                                    <Field type="text" className='form-control' name='nom' id='nom' />
                                    <p className='text-danger'><ErrorMessage name='nom'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="numero" className='form-label'>Numéro du prestataire</label>
                                    <Field type="text" className='form-control' name='numero' id='numero' />
                                    <p className='text-danger'><ErrorMessage name='numero'></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="responsable" className='form-label'>Responsable</label>
                                    <Field type="text" className='form-control' id='responsable' name="responsable" />
                                    <p className='text-danger'><ErrorMessage name="responsable"></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className='form-label'>E-mail</label>
                                    <Field type="email" className='form-control' id='email' name="email" />
                                    <p className='text-danger'><ErrorMessage name="email"></ErrorMessage></p>
                                </div>

                                <div className="modal-footer bg-white">
                                    <button id='fermer' type="button" onClick={() => { resetForm(); setmessageError("") }} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div >
}

export default AjoutClient;
