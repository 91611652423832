import React, { useEffect, useState } from "react";
import { formatdate } from "../../formatDate";
import { InfoToken } from "../../infoToken";
import Layout from "../../layouts/Layout";
import { rechercheroperateurs } from "../../services/parametres/operateur";
import {
  rechercherallprestataires,
  rechercherprestataire,
  rechercherprestatairesliste,
} from "../../services/prestataires/prestatairesRequest";
import {
  rechercherallservices,
  rechercherpestataireservice,
  rechercherserviceparprestataire,
} from "../../services/prestataires/service";
import {
  rechercherpaiements,
  rechercherpaiement,
  statspaiementsprestataire,
  recherchernombrepaiement,
  recherchernombreetmontantpaiement,
} from "../../services/transactions/transactionsRequest";
import DateFilter from "../DateFilter";
import Paginate from "../pagination";
import moment from "moment";
import { Oval } from "react-loader-spinner";
import { rechercherprestatairestats } from "../../services/statistiques/StatistiquesRequest";
import { convertNumber } from "../../convertToMoneyFormat";

function Transaction() {
  let [listeTransactions, setlisteTransactions] = useState([]);
  let [listeMontantTransactions, setListeMontantTransactions] = useState([]);
  const [listeOperateur, setlisteOperateur] = useState([]);
  const [listeServices, setlisteServices] = useState([]);
  const [listePrestataire, setlistePrestataire] = useState([]);
  let [filterParametres, setfilterParametres] = useState({
    codeOperateur: "",
    dateDebut: "",
    dateFin: "",
    param: "",
    prestataire_id: "",
    service_id: "",
    valide: "",
  });
  const [montantAreverser, setmontantAreverser] = useState("");

  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [totalElement2, settotalElement2] = useState(0);
  const [totalElementByPage2, setTotalElementByPage2] = useState(0);
  const [nombreTransactionTotal, setNombreTransactionTotal] = useState(0);
  const [montantTransactionTotal, setMontantTransactionTotal] = useState(0);
  const [pageActive, setPageActive] = useState(1);
  const [loading, setloading] = useState(false);
  const [infoStats, setinfoStats] = useState({});
  const [currentComponent, setCurrentComponent] = useState("TRANSACTION");

  const listeStatut = [
    { id: 0, value: 2, label: "Echec" },
    { id: 1, value: 1, label: "Succès" },
  ];

  function tansformerObjetEnTableau(objet) {
    let keyTable = Object.keys(objet);
    let valueTable = Object.values(objet);
    let tableau = [];
    for (let i = 0; i < keyTable.length; i++) {
      let objet = { code: keyTable[i], name: valueTable[i] };
      tableau.push(valueTable[i]);
    }
    return tableau;
  }

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber);
    afficherTransactions(pageNumber);
  };

  const handlePageChange2 = (pageNumber) => {
    setPageActive(pageNumber);
    afficherNombreTransactions(pageNumber);
  };

  //  Récuperation des transactions
  const afficherTransactions = (currentPage) => {
    setloading(true);
    rechercherpaiement(filterParametres, currentPage)
      .then((res) => {
        setloading(false);
        setlisteTransactions([]);
        listeTransactions = res.data.donnee.data;
        setlisteTransactions(listeTransactions);
        settotalElement(res.data.donnee.total);
        setTotalElementByPage(res.data.donnee.per_page);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  //  Récupère les nombres transactions
  const afficherNombreTransactions = (currentPage) => {
    setloading(true);
    recherchernombrepaiement(filterParametres, currentPage)
      .then((res) => {
        setloading(false);
        if (Array.isArray(res.data["data"])) {
          setListeMontantTransactions(
            res.data && res.data.data ? res.data.data : []
          );
        } else {
          setListeMontantTransactions(tansformerObjetEnTableau(res.data["data"]))
        }
        settotalElement2(res.data && res.data.total ? res.data.total : 0);
        setTotalElementByPage2(
          res.data && res.data.per_page ? res.data.per_page : 0
        );
      })
      .catch((error) => {
        setloading(false);
      });
  };

  // Nombre et Montant total des transaction
  const afficherNombreEtMontantTotalTransactions = (currentPage) => {
    setloading(true);
    recherchernombreetmontantpaiement(filterParametres, currentPage)
      .then((res) => {
        setloading(false);
        setNombreTransactionTotal(
          res.data && res.data.donnee ? res.data.donnee.nombreTotal : "0"
        );
        setMontantTransactionTotal(
          res.data && res.data.donnee ? res.data.donnee.montantTotal : "0"
        );
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const afficherOperateur = () => {
    rechercheroperateurs()
      .then((res) => {
        setlisteOperateur(res.data.donnee);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const afficherService = (id = 0) => {
    rechercherserviceparprestataire(id).then((res) => {
      setlisteServices(res.data.donnee);
    });
  };

  const displayAllservice = () => {
    rechercherallservices().then((res) => {
      setlisteServices(res.data.donnee);
    });
  };

  const afficherPrestataire = () => {
    rechercherallprestataires().then((res) => {
      setlistePrestataire(res.data.donnee);
    });
  };

  //Filtrer par prestataire
  const handleRecupereIdPrestataireAndFilter = (e) => {
    //setPageActive(1);
    e.target.value === ""
      ? displayAllservice()
      : afficherService(e.target.value);
    filterParametres.prestataire_id = e.target.value;
    filterParametres.service_id = "";
    setfilterParametres(filterParametres);
    recuperermontantareverser();
    afficherTransactions(1);
    afficherStats();
  };

  //Filtrer par opérateur
  const filterOnOperator = (e) => {
    filterParametres.codeOperateur = e.target.value;
    setfilterParametres(filterParametres);
    if (currentComponent === "TRANSACTION") {
      afficherTransactions(1);
      afficherStats();
    } else {
      afficherNombreTransactions(1);
      afficherNombreEtMontantTotalTransactions(1)
    }
  };

  //Filtrer par Service
  const filterOnService = (e) => {
    filterParametres.service_id = e.target.value;
    setfilterParametres(filterParametres);
    if (currentComponent === "TRANSACTION") {
      afficherTransactions(1);
      afficherStats();
      recuperermontantareverser();
    } else {
      afficherNombreTransactions(1);
      afficherNombreEtMontantTotalTransactions(1)
    }
  };

  //Filtrer par statuts
  const filterOnStatut = (e) => {
    filterParametres.valide = e.target.value;
    setfilterParametres(filterParametres);
    afficherTransactions(1);
    recuperermontantareverser();
    afficherStats();
  };

  // 0 echech 1 succes valide

  //Zone de recherche
  const handleSearchTransaction = (e) => {
    filterParametres.param = e.target.value;
    setfilterParametres(filterParametres);
    afficherTransactions(1);
    afficherStats();
  };

  //Recherche sur la date
  const filterOnDate = (dateDebut, dateFin) => {
    filterParametres.dateDebut =
      dateDebut !== null && dateDebut !== ""
        ? moment(dateDebut).format("yyyy-MM-DD")
        : "";
    filterParametres.dateFin =
      dateFin !== null && dateFin !== ""
        ? moment(dateFin).format("yyyy-MM-DD")
        : "";
    setfilterParametres(filterParametres);
    if (currentComponent === "TRANSACTION") {
      afficherTransactions(1);
      afficherStats();
    } else {
      afficherNombreTransactions(1);
      afficherNombreEtMontantTotalTransactions(1)
    }
  };

  const afficherStats = () => {
    statspaiementsprestataire(filterParametres).then((res) => {
      setinfoStats(res.data.donnee);
    });
  };

  //afficher montant à reverser
  const recuperermontantareverser = () => {
    let infoprestataire = {
      prestataires_id: filterParametres.prestataire_id,
      service_id: filterParametres.service_id,
    };
    rechercherprestatairestats(infoprestataire)
      .then((reponse) => {
        setmontantAreverser(reponse.data.donnee);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const changeViewComponent = (view) => {
    filterParametres.dateDebut = "";
    filterParametres.dateFin = "";
    setfilterParametres({
      codeOperateur: "",
      dateDebut: "",
      dateFin: "",
      param: "",
      prestataire_id: "",
      service_id: "",
      valide: "",
    });
    setPageActive(1);
    if (view === "TRANSACTION") {
      setCurrentComponent("TRANSACTION");
      afficherTransactions(1);
    } else {
      setCurrentComponent("AUTRES");
      afficherNombreTransactions(1);
      afficherNombreEtMontantTotalTransactions(1)
    }
  };

  useEffect(() => {
    afficherNombreTransactions(pageActive);
    afficherNombreEtMontantTotalTransactions(pageActive);
    afficherTransactions(pageActive);
    afficherOperateur();
    afficherPrestataire();
    displayAllservice();
    recuperermontantareverser();
    afficherStats();
  }, []);

  const rowListeTransaction = listeTransactions.map((item, index) => (
    <tr key={index}>
      <td>{item.service !== null && item.service.designation} </td>
      <td>{item.reference}</td>
      <td>
        {item.operateurMobileMoney_id !== null && item.operateurMobileMoney_id}
      </td>
      <td>{item.numero}</td>
      <td> {item.valeur} </td>
      <td>
        {item.datePaiement !== undefined &&
          moment(item.datePaiement).format("DD-MM-yyyy H:m")}
      </td>
      <td
        className={item.valide ? "text-success fw-bold" : "text-danger fw-bold"}
      >
        {item.valide ? "Succès" : "Echec"}
        {localStorage.getItem("role") === "ROLE_ADMIN" && !item.valide && (
          <button className="btn-sm btn">
            <i className="fas fa-redo"></i>
          </button>
        )}
      </td>
    </tr>
  ));

  const rowListeMontantTransaction = listeMontantTransactions.map(
    (item, index) => (
      <tr key={index}>
        <td>{item.service !== null && item.service.designation} </td>
        <td>{item.nombre}</td>
        <td>{item.montant} FCFA</td>
      </tr>
    )
  );

  const optionListeOperateur = listeOperateur.map((item, index) => (
    <option key={index} value={item.id}>
      {item.designation + " | " + item.id}
    </option>
  ));

  const optionListeService = listeServices.map((item) => (
    <option key={item.id} value={item.id}>
      {item.designation}
    </option>
  ));

  const optionPrestataire = listePrestataire.map((item) => (
    <option key={item.id} value={item.id}>
      {item.nom}
    </option>
  ));

  const optionStatut = listeStatut.map((item) => (
    <option key={item.id} value={item.value}>
      {item.label}
    </option>
  ));

  return (
    <Layout>
      <div>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 30 }}
        >
          <div className="fw-bold mx-3">
            <p
              style={{
                fontSize: 21,
                cursor: "pointer",
                color: currentComponent === "TRANSACTION" ? "#f9ba14" : "black",
              }}
              onClick={() => changeViewComponent("TRANSACTION")}
            >
              Transactions
            </p>
          </div>
          <div style={{ width: 2, height: 30, backgroundColor: "black" }} />
          <div className="fw-bold mx-3">
            <p
              style={{
                fontSize: 21,
                cursor: "pointer",
                color: currentComponent === "AUTRES" ? "#f9ba14" : "black",
              }}
              onClick={() => changeViewComponent("AUTRES")}
            >
              Montant par services
            </p>
          </div>
        </div>

        {/* TRANSACTION */}
        {currentComponent === "TRANSACTION" ? (
          <div>
            <div className="d-flex mb-5">
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold ">Transactions du jour</h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    {infoStats.nbreTransactionJour === undefined
                      ? 0
                      : infoStats.nbreTransactionJour}
                    /{" "}
                    {infoStats.montantTransactionJour === undefined
                      ? 0
                      : convertNumber(infoStats.montantTransactionJour)}{" "}
                    FCFA
                  </p>
                </div>
              </div>
              <div className="card mx-2  shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold">Transactions du mois</h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    {infoStats.nbreTransactionMois === undefined
                      ? 0
                      : infoStats.nbreTransactionMois}
                    /{" "}
                    {infoStats.montantTransactionMois === undefined
                      ? 0
                      : convertNumber(infoStats.montantTransactionMois)}{" "}
                    FCFA
                  </p>
                </div>
              </div>
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold ">Transactions globale</h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    {infoStats.nbreTransaction === undefined
                      ? 0
                      : infoStats.nbreTransaction}
                    /{" "}
                    {infoStats.montantTransaction === undefined
                      ? 0
                      : convertNumber(infoStats.montantTransaction)}{" "}
                    FCFA
                  </p>
                </div>
              </div>
              <div className="card mx-2 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold ">Montant à reverser</h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    <span className="fw-bold">
                      {montantAreverser !== undefined &&
                        convertNumber(montantAreverser)}{" "}
                      FCFA
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="search">
              <div className="d-flex justify-content-end">
                <div className="ui icon input">
                  <input
                    onChange={(e) => handleSearchTransaction(e)}
                    type="text"
                    placeholder="Search..."
                  />
                  <i className="circular search link icon"></i>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="d-flex justify-content-between mt-4">
                <div className="fw-bold mx-1">
                  <legend>Transactions</legend>
                </div>

                <div className="d-flex justify-content-end">
                  <DateFilter filterElement={filterOnDate} />

                  <div className="mx-1">
                    <select
                      onChange={(e) => filterOnOperator(e)}
                      className="form-select form-select-sm "
                      aria-label=".form-select-sm example"
                    >
                      <option value="">Sélectionner l'opérateur</option>
                      {optionListeOperateur}
                    </select>
                  </div>
                  {localStorage.getItem("role") === "ROLE_ADMIN" && (
                    <div className="mx-2">
                      <select
                        onChange={handleRecupereIdPrestataireAndFilter}
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                      >
                        <option value="">Sélectionner le prestataire</option>
                        {optionPrestataire}
                      </select>
                    </div>
                  )}
                  <div className="mx-2">
                    <select
                      onChange={filterOnService}
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                    >
                      <option value="">Sélectionner le service</option>
                      {optionListeService}
                    </select>
                  </div>
                  <div className="ml-2">
                    <select
                      onChange={filterOnStatut}
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                    >
                      <option value="">Sélectionner le statut</option>
                      {optionStatut}
                    </select>
                  </div>
                </div>
              </div>
              <table className="ui celled padded table">
                <thead>
                  <tr>
                    <th className="single line">Service</th>
                    <th>Ref transaction</th>
                    <th>opérateur</th>
                    <th>Numéro</th>
                    <th>Montant</th>
                    <th>date</th>
                    <th>statut</th>
                  </tr>
                </thead>
                <tbody>{rowListeTransaction}</tbody>
                <tfoot>
                  <tr>
                    <th colSpan="7">
                      <div className="d-flex justify-content-center">
                        <Paginate
                          pageActive={pageActive}
                          handlePageChange={handlePageChange}
                          nbreElementParPage={totalElementByPage}
                          nbreTotalElmentPage={totalElement}
                        />
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              {loading && (
                <div className="d-flex justify-content-center">
                  <Oval
                    width={30}
                    height={30}
                    color="#344b7b"
                    visible={loading}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex mb-5">
              <div className="card shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold ">
                    Nombre total des transaction
                  </h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    {nombreTransactionTotal}
                  </p>
                </div>
              </div>
              <div className="card mx-2  shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    Montant total des transactions
                  </h5>
                  <p className="card-text fw-bold fs-5 colorBlueTextSkanPay">
                    {convertNumber(montantTransactionTotal)} FCFA
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="search" style={{ marginTop: 60 }}>
              <div className="d-flex justify-content-end">
                <div className="ui icon input">
                  <input
                    onChange={(e) => handleSearchTransaction(e)}
                    type="text"
                    placeholder="Search..."
                  />
                  <i className="circular search link icon"></i>
                </div>
              </div>
            </div> */}

            <div className="container">
              <div className="d-flex justify-content-between mt-4">
                <div className="fw-bold mx-1">
                  <legend>Montant transaction</legend>
                </div>

                <div className="d-flex justify-content-end">
                  <DateFilter filterElement={filterOnDate} />

                  <div className="mx-1">
                    <select
                      onChange={(e) => filterOnOperator(e)}
                      className="form-select form-select-sm "
                      aria-label=".form-select-sm example"
                    >
                      <option value="">Sélectionner l'opérateur</option>
                      {optionListeOperateur}
                    </select>
                  </div>
                  <div className="mx-2">
                    <select
                      onChange={filterOnService}
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                    >
                      <option value="">Sélectionner le service</option>
                      {optionListeService}
                    </select>
                  </div>
                </div>
              </div>
              <table className="ui celled padded table">
                <thead>
                  <tr>
                    <th className="single line">Service</th>
                    <th>Nombre transaction</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>{rowListeMontantTransaction}</tbody>
                <tfoot>
                  <tr>
                    <th colSpan="7">
                      <div className="d-flex justify-content-center">
                        <Paginate
                          pageActive={pageActive}
                          handlePageChange={handlePageChange2}
                          nbreElementParPage={totalElementByPage2}
                          nbreTotalElmentPage={totalElement2}
                        />
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              {loading && (
                <div className="d-flex justify-content-center">
                  <Oval
                    width={30}
                    height={30}
                    color="#344b7b"
                    visible={loading}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Transaction;
