import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { rechercherserviceparprestataire } from '../../services/prestataires/service';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup'
import { enregistrerdemandereversement } from '../../services/demande-reversement/DemandeRequest';
import { rechercherprestatairestats } from '../../services/statistiques/StatistiquesRequest';

function AjoutDemandeReversement(props) {

    const [listeService, setlisteService] = useState([]);
    const [loading, setloading] = useState(false)
    const [messageError, setmessageError] = useState("")
    const [montantAreverser, setmontantAreverser] = useState("");
    const initialValues = {
        service_id: "",
        commentaire: "",
        statut: false
    }

    const validationValuesSchema = yup.object().shape({
        service_id: yup.string().required("Veuillez sélectionner le service"),
        commentaire: yup.string().required("Veuillez saisir un commentaire")
    })

    const handleOnsubmitDemande = (values) => {
        setloading(true)
        enregistrerdemandereversement(values).then(() => {
            setloading(false)
            setmessageError("");
            props.afficherLesDemandes(1);
            document.getElementById("closeAddDemande").click();
        }).catch((error) => {
            setloading(false);
            console.log(error.response.data);
            setmessageError(error.response.data.donnee);
        })
    }

    const handleChangeService = (value)=>{
        
            let infoprestataire = {
                prestataires_id: "",
                service_id: value
            };
            rechercherprestatairestats(infoprestataire).then((reponse)=>{
                setmontantAreverser(reponse.data.donnee);
            }).catch((error)=>{
                console.log(error.response.data);
            })
        
    }


    //Option liste service
    const optionListeService = listeService.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));
    //Requête pour récuper la liste des service du prestataire
    const getListService = () => {
        rechercherserviceparprestataire(localStorage.getItem("prestataires")).then((res) => {
            setlisteService(res.data.donnee);
        })
    };

    useEffect(() => {
        getListService();
        handleChangeService("");
    }, [])



    return (
        <div className="modal fade" id="ajoutDemande" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content blueSkanpay">
                    <div className="modal-header text-white">
                        <h5 className="modal-title" id="staticBackdropLabel">Demande Reversement</h5>
                        <button type="button" onClick={()=>setmessageError("")} className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-white">
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize                           
                            validationSchema={validationValuesSchema}
                            onSubmit={handleOnsubmitDemande}
                        >
                            {({ resetForm }) => (
                                
                                <Form
                                    onChange={(e)=>{e.target.type === 'select-one' && handleChangeService(e.target.value)}}
                                >
                                    {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}
                                    <div className='mb-3'> <Field component="select"  className="form-select" name="service_id" id="service_id">
                                        <option value="">Veuillez sélectionner le service</option>
                                        {optionListeService}
                                        </Field>
                                        <p className='text-danger'><ErrorMessage name="service_id"></ErrorMessage></p>
                                    </div>
                                    <div>
                                        <input className='form-control' type="number" disabled value={montantAreverser} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="commentaire" className='form-label'>Commentaire</label>
                                        <Field as="textarea" name="commentaire" id="commentaire" className="form-control"></Field>
                                        <p className='text-danger'><ErrorMessage name="commentaire"></ErrorMessage></p>

                                    </div>


                                    <div className="modal-footer bg-white">
                                        <button id='closeAddDemande' type="button" onClick={() => { resetForm(); setmessageError("") }} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                        {!loading && <button type="submit" className="btn btn-sm btn-primary">Enregistrer</button>}
                                    </div>
                                </Form>

                            )}
                        </Formik>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AjoutDemandeReversement