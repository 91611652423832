import React, { useEffect, useState } from 'react'
import Layout from '../../layouts/Layout'
import AjoutService from './AjoutService'
import { Oval } from 'react-loader-spinner';
import { rechercherserviceparprestataire } from '../../services/prestataires/service';
import DetailCommission from './DetailCommission';

function Services() {
    const [loading, setloading] = useState(false)
    const [listeServices, setlisteServices] = useState([]);
    const [infoService, setinfoService] = useState({})

    //Affichage de la liste des services du prestataire
    const afficherService = () => {
        setloading(true)
        rechercherserviceparprestataire(localStorage.getItem("prestataires")).then((res) => {
            setloading(false)
            setlisteServices(res.data.donnee);
        }).catch((error) => {
            setloading(false)
        })
    }

    useEffect(() => {
        afficherService()
    }, [])

    const rowListeService = listeServices.map((item, index) => (
        <tr key={index}>
            <td>{item.designation}</td>
            <td>{item.urlNotification}</td>
            <td>{item.description}</td>
            <td width="20%" className='text-center'>
                <button onClick={() => setinfoService(item)} data-bs-target="#detailCommission" data-bs-toggle="modal" className='btn btn-sm btn-dark'><i className='fa fa-eye'></i></button>
            </td>
            <td>
                <button onClick={() => setinfoService(item)} data-bs-target="#ajoutService" data-bs-toggle="modal" className='btn btn-sm'><i className='fa fa-pencil-alt'></i></button>
            </td>
        </tr>
    ))

    return (
        <Layout>
            <div>

                <div className='Filtre'>

                </div>

                <div className='container'>
                    <div className='d-flex justify-content-between mt-5'>
                        <div className='fw-bold'>
                            <legend>Services</legend>
                        </div>

                        <div className=''>
                            <a type='button' onClick={()=>setinfoService({})} data-bs-toggle="modal" data-bs-target="#ajoutService" className='btn colorYelloSkanPay text-black  btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau service</a>
                        </div>
                    </div>
                    <table className="ui celled padded table">
                        <thead>
                            <tr>
                                <th>Désignation</th>
                                <th>URL de notification</th>
                                <th>description</th>
                                <th>Commissions appliquées</th>
                                <th width="15%">Action rapide</th>
                            </tr>

                        </thead>
                        <tbody>
                            {rowListeService}
                        </tbody>

                    </table>
                    {loading &&
                        <div className='d-flex justify-content-center'>
                            <Oval width={30} height={30} color="#344b7b" visible={loading} />
                        </div>
                    }
                    <AjoutService data={infoService} afficherService={afficherService} />
                    <DetailCommission data={infoService} />
                </div>
            </div>
        </Layout>
    )
}

export default Services