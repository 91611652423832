import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import { updateProfilUtilisateur } from "../../services/utilisateurs/UtilisateursRequest";
import { Oval } from "react-loader-spinner";

function UpdateProfilUtilisateur(props) {
  const [messageError, setMessageError] = useState("");
  const [profilSelected, setProfilSelected] = useState("");
  const [loading, setloading] = useState(false);

  const listeDesProfils = [
    {
      id: 1,
      label: "Admin",
      value: "ROLE_ADMIN",
    },
    {
      id: 2,
      label: "Prestataire",
      value: "ROLE_ADMIN_PRESTATAIRE",
    },
  ];

  useEffect(() => {
    setProfilSelected("")
  }, []);

  let initialValues = {
    id: props.data.id !== undefined ? props.data.id : "",
    profil: props.data.profil !== undefined ? props.data.profil : "",
  };

  const validationValuesSchema = yup.object().shape({
    profil: yup.string().required("Veuillez sélectionner un profil"),
  });

  //Enregistrement de l'utilisateur
  const handleOnsubmitUtilisateur = (values) => {
    setloading(true);
    updateProfilUtilisateur(values).then((res) => {
        setloading(false);
        props.afficherListeUtilisateur(1);
        props.setPageActive(1);
        document.getElementById('closeUpdateProfil').click();
      }).catch((err) => {
        setloading(false);
        console.log(err.response.data);
      })
  };

  return (
    <div
      className="modal fade"
      id="updateProfilUtilisateur"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content blueSkanpay">
          <div className="modal-header text-white">
            <h5 className="modal-title" id="staticBackdropLabel">
              PROFIL
            </h5>
            <button
              type="button"
              className="bg-danger btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body bg-white">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationValuesSchema}
              onSubmit={handleOnsubmitUtilisateur}
            >
              {({ resetForm }) => (
                <Form>
                  {messageError !== "" && (
                    <p className="text-danger">
                      <i className="fa fa-exclamation-triangle"></i>{" "}
                      {messageError}
                    </p>
                  )}
                  <div className="mb-3">
                    <label htmlFor="profil" className="form-label">
                      Profil
                    </label>
                    <Field
                      as="select"
                      name="profil"
                      id="profil"
                      className="form-control"
                    >
                      <option defaultValue disabled value="">
                        Sélectionner un profil
                      </option>
                      {listeDesProfils.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.label}
                        </option>
                      ))}
                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="profil"></ErrorMessage>
                    </p>
                  </div>

                  <div className="modal-footer bg-white">
                    <button
                      id="closeUpdateProfil"
                      type="button"
                      onClick={() => {
                        resetForm();
                        setMessageError("");
                      }}
                      className="btn btn-sm btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                    <Oval
                      width={30}
                      height={30}
                      color="#344b7b"
                      visible={loading}
                    />
                    {!loading && (
                      <button type="submit" className="btn btn-sm btn-primary">
                        Enregistrer
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfilUtilisateur;
