import React from 'react';
import Navbar from './Navbar';
function Layout(props) {
  return <>
    <Navbar />
    <div className='container mt-3'>
        {props.children}
    </div>
  </>
}

export default Layout;
