import jwt_decode from 'jwt-decode';

export const isAuthenticated = () => {

    let tokenValid = false;

    try {
      if (localStorage.getItem("tokenskanpay").replace(/[\""]+/g, '') && localStorage.getItem("role")) {  
          tokenValid = true
      }
    } catch (error) {
      tokenValid = false
    }
    return tokenValid;

  }
