import axios from "axios"
import { headers } from "../headers"
import { baseUrl } from "../URL"

export const recherchercommissionservice = async(id)=>{
    return axios.get(baseUrl("recherchercommissionservice/"+id), headers);
}

export const supprimeroeprateurservice = async(id)=>{
    return axios.delete(baseUrl("supprimeroeprateurservice/"+id), headers);
}

export const rechercheroperateurservicenonajouter = async(idservice)=>{
    return axios.get(baseUrl("rechercheroperateurservicenonajouter/"+idservice), headers);
};

export const enregistrercommissionoperateur = async(values)=>{
    return axios.post(baseUrl("enregistrercommissionoperateur"), values, headers);
}

export const modifiercommissionoperateur = async(values)=>{
    return axios.post(baseUrl("modifiercommissionoperateur"), values, headers);
}