import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from './pays';
import { enregistrerpays } from '../../services/parametres/pays';
import $ from 'jquery';
import { Oval } from 'react-loader-spinner';

function AjouPays(props) {

    const [paysSelected, setpaysSelected] = useState(null);
    const [messageError, setmessageError] = useState("");
    const [loading, setloading] = useState(false);


    const handleOnSubmit = () => {
        setloading(true);
        enregistrerpays(paysSelected).then((res) => {
            setloading(false);
            props.afficherPays();
            setmessageError("");
            setpaysSelected(null);
            $("#fermerModalPays").trigger('click');
        }).catch((error) => {
            setloading(false);
            console.log(error.response.data);
            error.response.data.donnee.id !== undefined && setmessageError("Veuillez sélectionner le pays");
            error.response.data.donnee.designation !== undefined && setmessageError("Veuillez sélectionner le pays");
            (error.response.data.donnee !== "") && setmessageError(error.response.data.donnee);

        })
    }

    return <div className="modal fade" id="ajoutPays" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">Pays</h5>
                    <button type="button" onClick={() => setmessageError("")} className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">
                    {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                    <div className='mbt-3'>
                        <Autocomplete
                            id="pays"
                            sx={{ width: "100%" }}
                            options={countries}
                            autoHighlight
                            value={paysSelected}
                            onChange={(_event, newItem) => {
                                setpaysSelected(newItem);
                            }}
                            getOptionLabel={(option) => option.designation}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.designation} ({option.id})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Sélectionner le pays"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <div className="modal-footer bg-white">
                            <button type="button" onClick={() => setmessageError("")} id="fermerModalPays" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>

                            <Oval width={30} height={30} color="#344b7b" visible={loading} />

                            {!loading && <button type="submit" onClick={handleOnSubmit} className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default AjouPays;
