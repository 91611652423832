import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup'
import { enregistrerpays, rechercherpays } from '../../services/parametres/pays';
import $ from 'jquery';
import { enregistreroperateur, modifieroperateur } from '../../services/parametres/operateur';
import { Oval } from 'react-loader-spinner';

function AjoutOperateur(props) {

    const [listePays, setlistePays] = useState([]);
    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const initialValues = {
        id: props.data.id !== undefined ? props.data.id : "",
        pays_id: props.data.pays_id !== undefined ? props.data.pays_id : "",
        designation: props.data.designation !== undefined ? props.data.designation : "",
        classeTraitement: props.data.classeTraitement !== undefined ? props.data.classeTraitement : ""
    }

    const validationValuesSchema = yup.object().shape({
        id: props.data.id !== undefined ? yup.string() : yup.string().required("Veuillez saisir le code de l'opérateur"),
        pays_id: yup.string().required("Veuillez sélectionner le pays"),
        designation: yup.string().required("Veuillez entrer la désignation"),
    })


    const afficherListePays = () => {
        rechercherpays().then((res) => {
            setlistePays(res.data.donnee);
        })
    }

    useEffect(() => {
        afficherListePays();
    }, []);

    const optionListPays = listePays.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));

    const handleOnSubmitOperateur = (values) => {
        setloading(true)
        props.data.id !== undefined ?
        modifieroperateur(values).then((res) => {
            setloading(false);
            setmessageError("");
            props.afficherOperateurs();
            document.getElementById('fermerOperateur').click();
        }).catch((error) => {
            setloading(false);
            setmessageError(error.response.data.donnee);
        })
        :
        enregistreroperateur(values).then((res) => {
            setloading(false);
            setmessageError("");
            props.afficherOperateurs();
            document.getElementById('fermerOperateur').click();
        }).catch((error) => {
            setloading(false);
            setmessageError(error.response.data.donnee);
        })
    }

    return <div className="modal fade" id="ajoutoperateur" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">Opérateur</h5>
                    <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationValuesSchema}
                    onSubmit={handleOnSubmitOperateur}
                >
                    {({ resetForm }) => (

                        <Form>
                            <div className="modal-body bg-white">
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                <div className='mb-3'>
                                    <Field component="select" name="pays_id" className='form-select' id="paysOperateur">
                                        <option value="">Veuillez sélectionner le pays</option>
                                        {optionListPays}
                                    </Field>
                                    <p className='text-danger'><ErrorMessage name='pays_id'></ErrorMessage></p>
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="designation" className='form-label'>Désignation</label>
                                    <Field type="text" className='form-control' name='designation' id='designation' />
                                    <p className='text-danger'><ErrorMessage name='designation'></ErrorMessage></p>
                                </div>
                                {props.data.id === undefined && <div className='mb-3'>
                                    <label htmlFor="codeOperateur" className='form-label'>Code opérateur</label>
                                    <Field type="text" className='form-control' name='id' id='codeOperateur' />
                                    <p className='text-danger'><ErrorMessage name='id'></ErrorMessage></p>
                                </div>
                                }
                                <div className='mb-3'>
                                    <label htmlFor="classeTraitement" className='form-label'>Classe de traitement</label>
                                    <Field type="text" className='form-control' name='classeTraitement' id='classeTraitement' />
                                    <p className='text-danger'><ErrorMessage name='classeTraitement'></ErrorMessage></p>
                                </div>

                            </div>
                            <div className="modal-footer bg-white">
                                <button type="button" id='fermerOperateur' onClick={resetForm} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                {!loading && <button type="submit" className="btn btn-sm text-white blueSkanpay">Enregistrer</button>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </div >
}



export default AjoutOperateur;
