import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const rechercherprestatairestats = async(values)=>{
    return await axios.post(baseUrl("rechercherprestatairestats"),values,headers);
};

export const statistiques = async()=>{
    return await axios.get(baseUrl("statistiques"),headers);
};

export const rechercherGrapheNombreTransactions = async()=>{
    return await axios.get(baseUrl("rechercherGrapheNombreTransactions"),headers);
};
export const rechercherGrapheMontantTransactions = async()=>{
    return await axios.get(baseUrl("rechercherGrapheMontantTransactions"),headers);
};

export const rechercherGrapheNombreTransactionsParService = async()=>{
    return await axios.get(baseUrl("rechercherGrapheNombreTransactionsParService"), headers);
};

export const rechercherGrapheMontantTransactionsParService = async()=>{
    return await axios.get(baseUrl("rechercherGrapheMontantTransactionsParService"), headers);
}

