// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
    { id: 'AD', designation: 'Andorra'},
    {
        id: 'AE',
        designation: 'United Arab Emirates',
    },
    { id: 'AF', designation: 'Afghanistan' },
    {
        id: 'AG',
        designation: 'Antigua and Barbuda',
    },
    { id: 'AI', designation: 'Anguilla' },
    { id: 'AL', designation: 'Albania' },
    { id: 'AM', designation: 'Armenia' },
    { id: 'AO', designation: 'Angola'},
    { id: 'AQ', designation: 'Antarctica'},
    { id: 'AR', designation: 'Argentina' },
    { id: 'AS', designation: 'American Samoa' },
    { id: 'AT', designation: 'Austria' },
    {
        id: 'AU',
        designation: 'Australia'
    },
    { id: 'AW', designation: 'Aruba' },
    { id: 'AX', designation: 'Alland Islands' },
    { id: 'AZ', designation: 'Azerbaijan' },
    {
        id: 'BA',
        designation: 'Bosnia and Herzegovina'
    },
    { id: 'BB', designation: 'Barbados' },
    { id: 'BD', designation: 'Bangladesh' },
    { id: 'BE', designation: 'Belgium'},
    { id: 'BF', designation: 'Burkina Faso' },
    { id: 'BG', designation: 'Bulgaria' },
    { id: 'BH', designation: 'Bahrain' },
    { id: 'BI', designation: 'Burundi' },
    { id: 'BJ', designation: 'Benin' },
    { id: 'BL', designation: 'Saint Barthelemy' },
    { id: 'BM', designation: 'Bermuda' },
    { id: 'BN', designation: 'Brunei Darussalam' },
    { id: 'BO', designation: 'Bolivia' },
    { id: 'BR', designation: 'Brazil'},
    { id: 'BS', designation: 'Bahamas' },
    { id: 'BT', designation: 'Bhutan' },
    { id: 'BV', designation: 'Bouvet Island'},
    { id: 'BW', designation: 'Botswana' },
    { id: 'BY', designation: 'Belarus' },
    { id: 'BZ', designation: 'Belize' },
    {
        id: 'CA',
        designation: 'Canada'
    },
    {
        id: 'CC',
        designation: 'Cocos (Keeling) Islands'
    },
    {
        id: 'CD',
        designation: 'Congo, Democratic Republic of the'
    },
    {
        id: 'CF',
        designation: 'Central African Republic'
    },
    {
        id: 'CG',
        designation: 'Congo, Republic of the'
    },
    { id: 'CH', designation: 'Switzerland'},
    { id: 'CI', designation: "Cote d'Ivoire" },
    { id: 'CK', designation: 'Cook Islands' },
    { id: 'CL', designation: 'Chile'},
    { id: 'CM', designation: 'Cameroon' },
    { id: 'CN', designation: 'China'},
    { id: 'CO', designation: 'Colombia'},
    { id: 'CR', designation: 'Costa Rica' },
    { id: 'CU', designation: 'Cuba'},
    { id: 'CV', designation: 'Cape Verde' },
    { id: 'CW', designation: 'Curacao' },
    { id: 'CX', designation: 'Christmas Island'},
    { id: 'CY', designation: 'Cyprus' },
    { id: 'CZ', designation: 'Czech Republic' },
    {
        id: 'DE',
        designation: 'Germany'
    },
    { id: 'DJ', designation: 'Djibouti' },
    { id: 'DK', designation: 'Denmark' },
    { id: 'DM', designation: 'Dominica' },
    {
        id: 'DO',
        designation: 'Dominican Republic'
    },
    { id: 'DZ', designation: 'Algeria' },
    { id: 'EC', designation: 'Ecuador' },
    { id: 'EE', designation: 'Estonia' },
    { id: 'EG', designation: 'Egypt' },
    { id: 'EH', designation: 'Western Sahara' },
    { id: 'ER', designation: 'Eritrea' },
    { id: 'ES', designation: 'Spain' },
    { id: 'ET', designation: 'Ethiopia' },
    { id: 'FI', designation: 'Finland' },
    { id: 'FJ', designation: 'Fiji' },
    {
        id: 'FK',
        designation: 'Falkland Islands (Malvinas)'
    },
    {
        id: 'FM',
        designation: 'Micronesia, Federated States of'
    },
    { id: 'FO', designation: 'Faroe Islands'},
    {
        id: 'FR',
        designation: 'France'
    },
    { id: 'GA', designation: 'Gabon' },
    { id: 'GB', designation: 'United Kingdom'},
    { id: 'GD', designation: 'Grenada' },
    { id: 'GE', designation: 'Georgia' },
    { id: 'GF', designation: 'French Guiana' },
    { id: 'GG', designation: 'Guernsey' },
    { id: 'GH', designation: 'Ghana' },
    { id: 'GI', designation: 'Gibraltar' },
    { id: 'GL', designation: 'Greenland' },
    { id: 'GM', designation: 'Gambia' },
    { id: 'GN', designation: 'Guinea' },
    { id: 'GP', designation: 'Guadeloupe' },
    { id: 'GQ', designation: 'Equatorial Guinea' },
    { id: 'GR', designation: 'Greece' },
    {
        id: 'GS',
        designation: 'South Georgia and the South Sandwich Islands'
    },
    { id: 'GT', designation: 'Guatemala' },
    { id: 'GU', designation: 'Guam' },
    { id: 'GW', designation: 'Guinea-Bissau' },
    { id: 'GY', designation: 'Guyana' },
    { id: 'HK', designation: 'Hong Kong' },
    {
        id: 'HM',
        designation: 'Heard Island and McDonald Islands'
    },
    { id: 'HN', designation: 'Honduras' },
    { id: 'HR', designation: 'Croatia' },
    { id: 'HT', designation: 'Haiti' },
    { id: 'HU', designation: 'Hungary'},
    { id: 'ID', designation: 'Indonesia'},
    { id: 'IE', designation: 'Ireland' },
    { id: 'IL', designation: 'Israel' },
    { id: 'IM', designation: 'Isle of Man' },
    { id: 'IN', designation: 'India' },
    {
        id: 'IO',
        designation: 'British Indian Ocean Territory'
    },
    { id: 'IQ', designation: 'Iraq' },
    {
        id: 'IR',
        designation: 'Iran, Islamic Republic of'
    },
    { id: 'IS', designation: 'Iceland' },
    { id: 'IT', designation: 'Italy'},
    { id: 'JE', designation: 'Jersey'},
    { id: 'JM', designation: 'Jamaica' },
    { id: 'JO', designation: 'Jordan' },
    {
        id: 'JP',
        designation: 'Japan'
    },
    { id: 'KE', designation: 'Kenya' },
    { id: 'KG', designation: 'Kyrgyzstan' },
    { id: 'KH', designation: 'Cambodia' },
    { id: 'KI', designation: 'Kiribati' },
    { id: 'KM', designation: 'Comoros' },
    {
        id: 'KN',
        designation: 'Saint Kitts and Nevis'
    },
    {
        id: 'KP',
        designation: "Korea, Democratic People's Republic of"
    },
    { id: 'KR', designation: 'Korea, Republic of' },
    { id: 'KW', designation: 'Kuwait' },
    { id: 'KY', designation: 'Cayman Islands'},
    { id: 'KZ', designation: 'Kazakhstan'},
    {
        id: 'LA',
        designation: "Lao People's Democratic Republic"
    },
    { id: 'LB', designation: 'Lebanon' },
    { id: 'LC', designation: 'Saint Lucia'},
    { id: 'LI', designation: 'Liechtenstein' },
    { id: 'LK', designation: 'Sri Lanka'},
    { id: 'LR', designation: 'Liberia' },
    { id: 'LS', designation: 'Lesotho' },
    { id: 'LT', designation: 'Lithuania' },
    { id: 'LU', designation: 'Luxembourg' },
    { id: 'LV', designation: 'Latvia' },
    { id: 'LY', designation: 'Libya' },
    { id: 'MA', designation: 'Morocco' },
    { id: 'MC', designation: 'Monaco' },
    {
        id: 'MD',
        designation: 'Moldova, Republic of'
    },
    { id: 'ME', designation: 'Montenegro' },
    {
        id: 'MF',
        designation: 'Saint Martin (French part)'
    },
    { id: 'MG', designation: 'Madagascar' },
    { id: 'MH', designation: 'Marshall Islands' },
    {
        id: 'MK',
        designation: 'Macedonia, the Former Yugoslav Republic of'
    },
    { id: 'ML', designation: 'Mali'},
    { id: 'MM', designation: 'Myanmar'},
    { id: 'MN', designation: 'Mongolia'},
    { id: 'MO', designation: 'Macao'},
    {
        id: 'MP',
        designation: 'Northern Mariana Islands'
    },
    { id: 'MQ', designation: 'Martinique'},
    { id: 'MR', designation: 'Mauritania'},
    { id: 'MS', designation: 'Montserrat' },
    { id: 'MT', designation: 'Malta'},
    { id: 'MU', designation: 'Mauritius'},
    { id: 'MV', designation: 'Maldives'},
    { id: 'MW', designation: 'Malawi'},
    { id: 'MX', designation: 'Mexico'},
    { id: 'MY', designation: 'Malaysia'},
    { id: 'MZ', designation: 'Mozambique'},
    { id: 'NA', designation: 'Namibia'},
    { id: 'NC', designation: 'New Caledonia'},
    { id: 'NE', designation: 'Niger'},
    { id: 'NF', designation: 'Norfolk Island'},
    { id: 'NG', designation: 'Nigeria'},
    { id: 'NI', designation: 'Nicaragua'},
    { id: 'NL', designation: 'Netherlands'},
    { id: 'NO', designation: 'Norway'},
    { id: 'NP', designation: 'Nepal' },
    { id: 'NR', designation: 'Nauru'},
    { id: 'NU', designation: 'Niue' },
    { id: 'NZ', designation: 'New Zealand'},
    { id: 'OM', designation: 'Oman'},
    { id: 'PA', designation: 'Panama'},
    { id: 'PE', designation: 'Peru' },
    { id: 'PF', designation: 'French Polynesia'},
    { id: 'PG', designation: 'Papua New Guinea'},
    { id: 'PH', designation: 'Philippines'},
    { id: 'PK', designation: 'Pakistan'},
    { id: 'PL', designation: 'Poland'},
    {
        id: 'PM',
        designation: 'Saint Pierre and Miquelon'
    },
    { id: 'PN', designation: 'Pitcairn' },
    { id: 'PR', designation: 'Puerto Rico' },
    {
        id: 'PS',
        designation: 'Palestine, State of'
    },
    { id: 'PT', designation: 'Portugal'},
    { id: 'PW', designation: 'Palau' },
    { id: 'PY', designation: 'Paraguay' },
    { id: 'QA', designation: 'Qatar'},
    { id: 'RE', designation: 'Reunion'},
    { id: 'RO', designation: 'Romania'},
    { id: 'RS', designation: 'Serbia'},
    { id: 'RU', designation: 'Russian Federation'},
    { id: 'RW', designation: 'Rwanda'},
    { id: 'SA', designation: 'Saudi Arabia'},
    { id: 'SB', designation: 'Solomon Islands'},
    { id: 'SC', designation: 'Seychelles'},
    { id: 'SD', designation: 'Sudan'},
    { id: 'SE', designation: 'Sweden'},
    { id: 'SG', designation: 'Singapore'},
    { id: 'SH', designation: 'Saint Helena' },
    { id: 'SI', designation: 'Slovenia'},
    {
        id: 'SJ',
        designation: 'Svalbard and Jan Mayen'
    },
    { id: 'SK', designation: 'Slovakia' },
    { id: 'SL', designation: 'Sierra Leone'},
    { id: 'SM', designation: 'San Marino' },
    { id: 'SN', designation: 'Senegal'},
    { id: 'SO', designation: 'Somalia'},
    { id: 'SR', designation: 'Suriname' },
    { id: 'SS', designation: 'South Sudan'},
    {
        id: 'ST',
        designation: 'Sao Tome and Principe'
    },
    { id: 'SV', designation: 'El Salvador' },
    {
        id: 'SX',
        designation: 'Sint Maarten (Dutch part)'
    },
    {
        id: 'SY',
        designation: 'Syrian Arab Republic'
    },
    { id: 'SZ', designation: 'Swaziland' },
    {
        id: 'TC',
        designation: 'Turks and Caicos Islands'
    },
    { id: 'TD', designation: 'Chad' },
    {
        id: 'TF',
        designation: 'French Southern Territories'
    },
    { id: 'TG', designation: 'Togo'},
    { id: 'TH', designation: 'Thailand'},
    { id: 'TJ', designation: 'Tajikistan' },
    { id: 'TK', designation: 'Tokelau' },
    { id: 'TL', designation: 'Timor-Leste' },
    { id: 'TM', designation: 'Turkmenistan' },
    { id: 'TN', designation: 'Tunisia'},
    { id: 'TO', designation: 'Tonga'},
    { id: 'TR', designation: 'Turkey'},
    {
        id: 'TT',
        designation: 'Trinidad and Tobago'
    },
    { id: 'TV', designation: 'Tuvalu' },
    {
        id: 'TW',
        designation: 'Taiwan, Province of China'
    },
    {
        id: 'TZ',
        designation: 'United Republic of Tanzania'
    },
    { id: 'UA', designation: 'Ukraine' },
    { id: 'UG', designation: 'Uganda' },
    {
        id: 'US',
        designation: 'United States'
    },
    { id: 'UY', designation: 'Uruguay' },
    { id: 'UZ', designation: 'Uzbekistan' },
    {
        id: 'VA',
        designation: 'Holy See (Vatican City State)'
    },
    {
        id: 'VC',
        designation: 'Saint Vincent and the Grenadines'
    },
    { id: 'VE', designation: 'Venezuela' },
    {
        id: 'VG',
        designation: 'British Virgin Islands'
    },
    {
        id: 'VI',
        designation: 'US Virgin Islands'
    },
    { id: 'VN', designation: 'Vietnam' },
    { id: 'VU', designation: 'Vanuatu' },
    { id: 'WF', designation: 'Wallis and Futuna' },
    { id: 'WS', designation: 'Samoa' },
    { id: 'XK', designation: 'Kosovo' },
    { id: 'YE', designation: 'Yemen' },
    { id: 'YT', designation: 'Mayotte' },
    { id: 'ZA', designation: 'South Africa'},
    { id: 'ZM', designation: 'Zambia'},
    { id: 'ZW', designation: 'Zimbabwe' },
];