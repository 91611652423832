import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import { Oval } from "react-loader-spinner";
import { updateIdentifiant } from "../../services/identifiant/IdentifiantRequest";

function ModifierIdentifiant() {
  const [loading, setloading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setUsername(localStorage.getItem("skanpayuser"));
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setloading(true);
    setIsSubmit(true);
    setIsError(false);
    setErrorMessage("")
    if (newPassword !== confirmPassword) {
      setloading(false);
      setIsSubmit(false);
      setIsError(true);
      setErrorMessage("Les mots de passe ne sont pas identique");
    } else {
      const identifiantToUpdate = {
        ancienpassword: password,
        newpassword: newPassword,
        passwordconfirm: confirmPassword,
      };
      updateIdentifiant(identifiantToUpdate)
        .then((res) => {
          setloading(false);
          setIsSuccess(true);
          setIsError(false);
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setErrorMessage("")
        })
        .catch((err) => {
          setloading(false);
          setIsSubmit(false);
          setIsError(true);
          setErrorMessage(err.response.data.donnee);
        });
    }
  };

  return (
    <Layout>
      <div id="page-content-wrapper">
        <div className="container-fluid px-4">
          <div className="row rowfiltrecug">
            <div className="col-md-6">
              <h2>Modifier mes identifiants</h2>
            </div>
          </div>
          <form style={{ margin: "auto", width: "300px", marginTop: "80px" }}>
            <div className="row mt-2">
              <div className="col-md-12">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  value={username}
                  type="text"
                  className="form-control "
                  id="username"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label htmlFor="ancienpassword" className="form-label">
                  Ancien mot de passe
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  autoComplete="off"
                  className="form-control "
                  id="ancienpassword"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label htmlFor="nouveau" className="form-label">
                  Nouveau mot de passe
                </label>
                <input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  className="form-control "
                  id="nouveau"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label htmlFor="passwordConfirm" className="form-label">
                  Confirmer nouveau mot de passe
                </label>
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  className="form-control "
                  id="passwordConfirm"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <button
                  disabled={
                    isSubmit ||
                    password.trim().length == 0 ||
                    newPassword.trim().length == 0 ||
                    confirmPassword.trim().length == 0
                  }
                  onClick={handleOnSubmit}
                  style={{
                    backgroundColor: "#f9ba14",
                    color: "#000",
                    width: "100%",
                  }}
                  className="btn"
                >
                  <i className="fas fa-check-circle fa-lg"></i> MODIFIER
                </button>
              </div>
            </div>
            {isError && (
              <div className="alert alert-danger mt-3">
                <p>{errorMessage}</p>
              </div>
            )}
            {isSuccess && (
              <div className="alert alert-success mt-3">
                <p>Modification effectuée avec succès</p>
              </div>
            )}
            <div style={{ width: "60px", margin: "auto" }}>
              <div className="d-flex justify-content-center">
                <Oval
                  width={30}
                  height={30}
                  color="#344b7b"
                  visible={loading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default ModifierIdentifiant;
