import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router';
import Dashboard from './components/dashboard/Dashboard';
import Clients from './components/clients/Clients';
import Transaction from './components/transactions/Transaction';
import Reversements from './components/reversements/Reversements';
import DetailsClients from './components/clients/DetailsClients';
import { isAuthenticated } from './TokenValidation';
import Parametres from './components/parametres/Parametres';
import Login from './components/Login';
import Utilisateurs from './components/utilisateurs/Utilisateurs';
import Services from './components/services-prestataire/Services';
import DemandeReversement from './components/demande-reversement/DemandeReversement';
import ReversementOperateur from './components/reversement-operateur/ReversementOperateur';
import Inscription from './components/Inscription';
import ModifierIdentifiant from './components/modifier-identifiant/ModifierIdentifiant';

const ProtectedRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

function RoutesApp() {

    

    return (<React.Fragment>
        <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/inscription" element={<Inscription />} />
            <Route exact path="/dashboard" element={<ProtectedRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route exact path="/parametres" element={<ProtectedRoute />}>
                <Route exact path="/parametres" element={<Parametres />} />
            </Route>
            <Route exact path="/prestataires" element={<ProtectedRoute />}>
                <Route exact path="/prestataires" element={<Clients />} />
            </Route>
            <Route exact path="/prestataires/:idPrestataire" element={<ProtectedRoute />}>
                <Route exact path="/prestataires/:idPrestataire" element={<DetailsClients />} />
            </Route>
            <Route exact path="/services" element={<ProtectedRoute />}>
                <Route exact path="/services" element={<Services />} />
            </Route>
            <Route exact path="/transactions" element={<ProtectedRoute />}>
                <Route exact path="/transactions" element={<Transaction />} />
            </Route>
            <Route exact path="/reversements" element={<ProtectedRoute />}>
                <Route exact path="/reversements" element={<Reversements />} />
            </Route>
            <Route exact path="/reversementoperateur" element={<ProtectedRoute />}>
                <Route exact path="/reversementoperateur" element={<ReversementOperateur />} />
            </Route>
            <Route exact path="/demandesreversements" element={<ProtectedRoute />}>
                <Route exact path="/demandesreversements" element={<DemandeReversement />} />
            </Route>
            <Route exact path="/utilisateurs" element={<ProtectedRoute />}>
                <Route exact path="/utilisateurs" element={<Utilisateurs />} />
            </Route>
            <Route exact path="/identifiants" element={<ProtectedRoute />}>
                <Route exact path="/identifiants" element={<ModifierIdentifiant />} />
            </Route>
            <Route exact path="/" element={<ProtectedRoute />}>
                <Route exact path="/" element={<Navigate to="/dashboard" />} />
            </Route>

        </Routes>

    </React.Fragment>
    )
}

export default RoutesApp;
