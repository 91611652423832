import axios from "axios";
import { baseUrl } from "../URL";
import { headers } from "../headers";

export const enregistreroperateur = async (values) => {
    return await axios.post(baseUrl("enregistreroperateurparpays"), values, headers);
};
export const modifieroperateur = async (values) => {
    return await axios.post(baseUrl("modifieroperateur"), values, headers);
};



export const rechercheroperateurs = async()=>{
    return await axios.get(baseUrl("rechercheroperateurs"), headers);
};

export const rechercheroperateurparpays = async(id)=>{
    return await axios.get(baseUrl(`rechercheroperateurparpays/${id}`), headers);
}

export const changeretatoperateur = async(code)=>{
    return await axios.get(baseUrl("changeretatoperateur/"+code),headers);
}