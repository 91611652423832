import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout'
import { rechercheroperateurs } from '../../services/parametres/operateur';
import { changeretatprestataire, rechercherprestataire } from '../../services/prestataires/prestatairesRequest';
import { rechercherserviceparprestataire } from '../../services/prestataires/service';
import { rechercherprestatairestats } from '../../services/statistiques/StatistiquesRequest';
import { rechercherpaiement } from '../../services/transactions/transactionsRequest';
import Paginate from '../pagination';
import AjoutClient from './AjoutClient';
import DetailsTransactionClient from './DetailsTransactionClient';

function Clients() {

  const [listePrestataires, setlistePrestataires] = useState([]);
  const [infoPrestataireSelectionner, setinfoPrestataireSelectionner] = useState({});
  const [listeDesServicesDuPrestataire, setlisteDesServicesDuPrestataire] = useState([]);
  let [listeDesTransactionsDuPrestataire, setlisteDesTransactionsDuPrestataire] = useState([]);
  let [listeOperateur, setlisteOperateur] = useState([]);
  const [montantAreverser, setmontantAreverser] = useState("");
  const [Idprestaire, setIdprestaire] = useState("");
  const [loading, setloading] = useState(false);

  let [filterParametres, setfilterParametres] = useState({ param: "" });

  //Pagination Prestataire
  const [totalElement, settotalElement] = useState(0);
  const [totalElementByPage, setTotalElementByPage] = useState(0);
  const [pageActive, setPageActive] = useState(1);

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber)
    afficherPrestataires(pageNumber, filterParametres)
  }
  const afficherPrestataires = (currentPage) => {
    setloading(true)
    rechercherprestataire(currentPage, filterParametres).then((res) => {
      setlistePrestataires(res.data.donnee.data);
      settotalElement(res.data.donnee.total);
      setTotalElementByPage(res.data.donnee.per_page);
      setloading(false)
    }).catch((error) => {
      setloading(false)
    })
  }



  //Pagination Transaction d'un prestataire
  const [totalElementTransaction, settotalElementTransaction] = useState(0);
  const [totalElementByPageTransaction, setTotalElementByPageTransaction] = useState(0);
  const [pageActiveTransaction, setPageActiveTransaction] = useState(1);



  //Changement de statut du prestataire
  const handleStatut = (id) => {
    changeretatprestataire(id).then((res) => {
      afficherPrestataires();
    })
  }

  //Zone de recherche
  const handleSearchPrestataire = (e) => {
    filterParametres.param = e.target.value
    setfilterParametres(filterParametres);
    afficherPrestataires(pageActive);
  }


  const AfficherServicePrestataire = (idPrestataire) => {
    setlisteDesServicesDuPrestataire([]);
    rechercherserviceparprestataire(idPrestataire).then((res) => {
      setlisteDesServicesDuPrestataire(res.data.donnee);
    });
  }

  const AfficherOperateur = () => {
    setlisteOperateur([]);
    rechercheroperateurs().then((res) => {
      setlisteOperateur(res.data.donnee);
    });

  }

  const afficherTransactionPrestataire = (idPrestatire, currentPage) => {
    setloading(true);
    rechercherpaiement({ prestataire_id: idPrestatire }, currentPage).then((res) => {
      setloading(false);
      setIdprestaire(idPrestatire);
      setPageActiveTransaction(currentPage);
      setlisteDesTransactionsDuPrestataire([]);
      changeListePrestatairePaiement(res.data.donnee.data);
      settotalElementTransaction(res.data.donnee.total);
      setTotalElementByPageTransaction(res.data.donnee.per_page);
    }).catch(()=>{
      setloading(false);
    })
  }

  const AfficherMontantAreverserService = (params) => {
    rechercherprestatairestats(params).then((res) => {

      setmontantAreverser(res.data.donnee);
    });
  }


  const openDetailsTransaction = (idPrestataire) => {
    setIdprestaire(idPrestataire);
    AfficherOperateur();
    AfficherServicePrestataire(idPrestataire);
    AfficherMontantAreverserService({ prestataires_id: idPrestataire });
    afficherTransactionPrestataire(idPrestataire, 1);
  }


  const changeListePrestatairePaiement = (donnee) => {
    setlisteDesTransactionsDuPrestataire(donnee);
  }


  const rowPrestataire = listePrestataires.map((item) => (
    <tr key={item.id} onClick={() => setinfoPrestataireSelectionner(item)} >
      <td data-bs-toggle="modal" data-bs-target="#ajoutClient">{item.nom} </td>
      <td data-bs-toggle="modal" data-bs-target="#ajoutClient">{item.responsable}</td>
      <td data-bs-toggle="modal" data-bs-target="#ajoutClient">{item.email}</td>
      <td onClick={() => handleStatut(item.id)}> {item.typeEtat === "ACTIF" ? <a className='btn text-success'><i className='fa fa-sign-in-alt'></i> Actif</a> : <a type='button' className='btn text-danger'> <i className='fa fa-ban'></i> Inactif</a>} </td>

      <td onClick={() => openDetailsTransaction(item.id)}>
        <a className='btn text-primary fw-bold' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Détails transactions <i className='fa fa-eye'></i></a>
      </td>
      <td ><Link className='btn text-secondary fw-bold' to={"/prestataires/" + item.id}> <i className='fa fa-info-circle'></i> Info prestataire</Link></td>
    </tr>
  ));

  useEffect(() => {
    afficherPrestataires(pageActive);
    AfficherOperateur();
  }, []);

  return <Layout>
    <div>
      <div className='search d-flex justify-content-center mt-1'>
        <div className="ui icon input">
          <input onChange={(e) => handleSearchPrestataire(e)} type="text" placeholder="Search..." />
          <i className="circular search link icon"></i>
        </div>
      </div>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className='fw-bold'>
            <legend>Prestataires</legend>
          </div>
          <div className=''>
            <a type='button' data-bs-toggle="modal" data-bs-target="#ajoutClient" onClick={() => { setinfoPrestataireSelectionner({}) }} className='btn colorYelloSkanPay text-white btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau client</a>
            <AjoutClient afficherPrestataires={() => afficherPrestataires(1)} datas={infoPrestataireSelectionner} />
          </div>
        </div>
        <table className="ui celled table-hover padded table">
          <thead>
            <tr><th className="single line">Prestataire</th>
              <th>Responsable</th>
              <th>E-mail</th>
              <th>Statut</th>
              <th></th>
              <th></th>
            </tr></thead>
          <tbody>
            {rowPrestataire}
          </tbody>
          <tfoot>
            <tr><th colSpan="6">
              <div className='d-flex justify-content-center'>
                <Paginate pageActive={pageActive} handlePageChange={handlePageChange} nbreElementParPage={totalElementByPage} nbreTotalElmentPage={totalElement} />
              </div>

            </th>
            </tr></tfoot>
        </table>
        {loading &&
          <div className='d-flex justify-content-center'>
            <Oval width={30} height={30} color="#344b7b" visible={loading} />
          </div>
        }
        <DetailsTransactionClient
          montantAreverser={montantAreverser}
          infoPrestataire={infoPrestataireSelectionner}
          listeOperateur={listeOperateur}
          listeService={listeDesServicesDuPrestataire}
          listeTransactions={listeDesTransactionsDuPrestataire}
          handleListeTransactions={changeListePrestatairePaiement}
          handlesMmontantAreverser={setmontantAreverser}
          afficherTransactionPrestataire={afficherTransactionPrestataire}
          idPrestataire={Idprestaire}
          AfficherMontantAreverserService={AfficherMontantAreverserService}
          pageActive={pageActiveTransaction}
          setpageActive={setPageActiveTransaction}
          nbreElementParPage={totalElementByPageTransaction}
          setTotalElementByPageTransaction={setTotalElementByPageTransaction}
          nbreTotalElmentPage={totalElementTransaction}
          settotalElementTransaction={settotalElementTransaction}
          loading={loading}
        />

      </div>
    </div>
  </Layout>
}

export default Clients;
