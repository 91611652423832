import React, { useState } from 'react'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Oval } from 'react-loader-spinner'
import * as yup from 'yup'
import { rechercherpays } from '../../services/parametres/pays';
import { useEffect } from 'react';
import { rechercheroperateurparpays } from '../../services/parametres/operateur';
import { enregistrerreversementoperateur } from '../../services/reversement/reversementRequest';

function AjoutReversementOps(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");
    const [listePays, setlistePays] = useState([]);
    const [paysSelected, setpaysSelected] = useState("");
    const [listeOperateur, setlisteOperateur] = useState([]);

    let initialValues = {
        montant: "",
        dateVersement: "",
        operateur_id: ""
    };

    const validationValuesSchema = yup.object().shape({
        montant: yup.number("Veuillez saisir que des chiffres").required("Veuillez saisir le montant"),
        dateVersement: yup.date("Veuillez saisir une date valide").required("Veuillez sélectionner une date"),
        operateur_id: yup.string().required("Veuillez sélectionner l'opérateur")
    });

    const afficherListePays = () => {
        rechercherpays().then((res) => {
            setlistePays(res.data.donnee);
        })
    };

    const afficherOperateurs = (id) => {
        rechercheroperateurparpays(id).then((res) => {
            setlisteOperateur(res.data.donnee);
        })
    }

    const handleOnsubmitReversement = (values) => {
         setloading(true);
        (paysSelected === "") && (values.operateur_id = "");
         enregistrerreversementoperateur(values).then((res) => {
             setloading(false);
             props.afficherListeReversement(props.pageActive);
             setmessageError("");
             document.getElementById("fermer").click();
         }).catch((error) => {
            setloading(false);
            console.log(error.response.data.donnee);
             (error.response.data.donnee.operateur_id === undefined) && (setmessageError(error.response.data.donnee));
             ((typeof error.response.data.donnee) === "string") && (setmessageError(error.response.data.donnee));
         })
    };


    const handlePays = (idpays) => {
        setpaysSelected(idpays);
        idpays !== "" ? afficherOperateurs(idpays) : setlisteOperateur([]);
    }

    useEffect(() => {
        afficherListePays();
    }, []);

    const optionListPays = listePays.map((item) => (
        <option key={item.code} value={item.id}>{item.designation}</option>
    ));

    const optionListOperateur = listeOperateur.map((item, index) => (
        <option key={index} value={item.id}>{item.designation}</option>
    ));


    return (
        <div className="modal fade" id="ajoutReversementoperateur" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content blueSkanpay">
                    <div className="modal-header text-white">
                        <h5 className="modal-title" id="staticBackdropLabel">REVERSEMENT OPERATEUR</h5>
                        <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-white">

                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationValuesSchema}
                            onSubmit={handleOnsubmitReversement}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                    <div className='mb-3'>
                                        <select onChange={(e) => handlePays(e.target.value)} value={paysSelected} name="pays_id" className='form-select' id="pays_id">
                                            <option value="">Veuillez sélectionner le pays</option>
                                            {optionListPays}
                                        </select>
                                    </div>
                                    <div className='mb-3 mx-2'>
                                        <label htmlFor="operateur_id" className="form-label">Opérateur</label>
                                        <Field component="select" name="operateur_id" className='form-select' id="operateur_id">
                                            <option value="">Veuillez sélectionner l'opérateur</option>
                                            {optionListOperateur}
                                        </Field>
                                        <p className='text-danger'><ErrorMessage name="operateur_id"></ErrorMessage></p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label " htmlFor="dateVersement">Date de réversement</label>
                                        <Field className="form-control" type="date" id="dateVersement" name="dateVersement" />
                                        <p className='text-danger'><ErrorMessage name="dateVersement"></ErrorMessage></p>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="montant" className="form-label">Montant reverser</label>
                                        <Field type="number" className="form-control" id="montant" name="montant" />
                                        <p className='text-danger'><ErrorMessage name="montant"></ErrorMessage></p>
                                    </div>

                                    <div className="modal-footer bg-white">
                                        <button id='fermer' type="button" onClick={() => { resetForm(); setpaysSelected("") }} className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                        {!loading && <button type="submit" className="btn btn-sm btn-primary">Enregistrer</button>}

                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AjoutReversementOps