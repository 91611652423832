import React, { useEffect, useState } from 'react'
import { enregistrercommissionoperateur, modifiercommissionoperateur, recherchercommissionservice, rechercheroperateurservicenonajouter, supprimeroeprateurservice } from '../../services/service-prestataire/ServiceRequest'
import { Oval } from 'react-loader-spinner'

function DetailCommission(props) {

    const [listeOperateurs, setlisteOperateurs] = useState([]);
    const [listeOperateurNonUtilise, setlisteOperateurNonUtilise] = useState([]);
    const [operateurSelected, setoperateurSelected] = useState("");
    const [loading, setloading] = useState(false);
    const [loadingliste, setloadingliste] = useState(false);
    const [commission, setcommission] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [messageError, setmessageError] = useState("");

    const affichercommission = (idService) => {
        setloadingliste(true)
        recherchercommissionservice(idService).then((res) => {
            setloadingliste(false)
            setlisteOperateurs(res.data.donnee);
        }).catch((error) => {
            setloadingliste(false);
            console.log(error.response.data);
        })
    }

    const supprimeroperateur = (id) => {
        supprimeroeprateurservice(id).then((res) => {
            affichercommission(props.data.id);
            afficheroperateurs(props.data.id);
        })
    }

    const afficheroperateurs = (id) => {
        rechercheroperateurservicenonajouter(id).then((res) => {
            setlisteOperateurNonUtilise(res.data.donnee);
        })
    }

    const handleaddoperator = () => {
        setloading(true)
        isEdit ?
            modifiercommissionoperateur({
                operateur_id: operateurSelected,
                service_id: props.data.id,
                commission: commission
            }).then(() => {
                setloading(false);
                setcommission("");
                setisEdit(false);
                setmessageError("");
                afficheroperateurs(props.data.id);
                affichercommission(props.data.id);
            }).catch((err) => {
                setloading(false);
                (err.response.data.donnee.operateur_id !== undefined) && setmessageError("Veuillez sélectionner l'opérateur");
                (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);
            })
            : enregistrercommissionoperateur(
                localStorage.getItem("role") === "ROLE_ADMIN" ? {
                    operateur_id: operateurSelected,
                    service_id: props.data.id,
                    commission: commission
                } : {
                    operateur_id: operateurSelected,
                    service_id: props.data.id
                }
            ).then(() => {
                setloading(false)
                setcommission("");
                setmessageError("");
                afficheroperateurs(props.data.id);
                affichercommission(props.data.id);
            }).catch((err) => {
                setloading(false);
                (err.response.data.donnee.operateur_id !== undefined) && setmessageError("Veuillez sélectionner l'opérateur");
                (typeof err.response.data.donnee === "string") && setmessageError(err.response.data.donnee);
            })
    }

    const handleEditCommission = (values) => {
        setlisteOperateurNonUtilise([{
            id: values.operateurMobileMoney_id,
            designation: values.operateur[0]
        }]);

        setoperateurSelected(values.operateurMobileMoney_id);
    }

    useEffect(() => {
        props.data.id !== undefined && affichercommission(props.data.id);
        props.data.id !== undefined && afficheroperateurs(props.data.id);

    }, [props.data])

    const optionListOperateur = listeOperateurNonUtilise.map((item, index) => (
        <option key={index} value={item.id}>{item.designation} | {item.id}</option>
    ));

    return (
        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="detailCommission" tabIndex={-1} aria-labelledby="modelTitleId" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Détails commissions</h5>
                        <button type="button" onClick={() => { setoperateurSelected(""); setlisteOperateurNonUtilise([]); setisEdit(false); setmessageError(""); }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Opérateur</th>
                                    <th>Code</th>
                                    <th width="20%">Commission</th>
                                    <th width="25%">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listeOperateurs.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.operateur}</td>
                                        <td>{item.operateurMobileMoney_id}</td>
                                        <td>{item.commissionoperateur.valeur} {item.commissionoperateur.typeCommission === "POURCENTAGE" ? "%" : ""}</td>
                                        <td>
                                            <button className='btn text-danger btn-sm' onClick={() => supprimeroperateur(item.id)}>
                                                <i className='fa fa-trash-alt'></i> Retirer
                                            </button>
                                            {localStorage.getItem("role") === "ROLE_ADMIN" &&
                                                <button className='btn text-secondary btn-sm' onClick={() => { handleEditCommission(item); setisEdit(true) }}>
                                                    <i className='fa fa-pencil-alt'></i> Modifier
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='d-flex justify-content-center'>
                            <Oval width={30} height={30} color="#344b7b" visible={loadingliste} />
                        </div>
                    </div>
                    {messageError !== "" && <p className='text-danger px-2'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                    <div className="d-flex mx-2 mb-3 justify-content-end">
                        <div className='mx-2 mb-1'>
                            <select name="operateur" onChange={(e) => setoperateurSelected(e.target.value)} value={operateurSelected} id="operateur" className='form-control form-select'>
                                <option value="">Sélectionnez un opérateur</option>
                                {optionListOperateur}
                            </select>
                        </div>
                        {localStorage.getItem("role") === "ROLE_ADMIN" && <div className='mx-2 mb-1'>
                            <input type="number" placeholder='Valeur commission' name="commission" onChange={(e) => setcommission(e.target.value)} value={commission} id="commission" className='form-control' />
                        </div>}
                        <div className='align-self-center'>
                            <Oval width={30} height={30} color="#344b7b" visible={loading} />
                            {!loading && <button onClick={handleaddoperator} type="button" className="btn colorYelloSkanPay text-black btn-sm"> {isEdit ? <><i className='fas fa-save'></i> Enregister</> : <><i className='fa fa-plus-circle'></i> Ajouter opérateur</>} </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DetailCommission