import axios from "axios"
import { headers } from "../headers"
import { baseUrl } from "../URL"


export const enregistrercommission = async(values)=>{
    return axios.post(baseUrl("enregistrercommission"), values, headers );
}

export const rechercherallcommission = async(values)=>{
    return axios.post(baseUrl("rechercherallcommission"),values, headers );
}