import React, { useEffect, useState } from 'react';
import Layout from '../../layouts/Layout'
import { changeretatoperateur, rechercheroperateurparpays, rechercheroperateurs } from '../../services/parametres/operateur';
import { rechercherpays, supprimerpays } from '../../services/parametres/pays';
import AjouPays from './AjouPays';
import AjoutOperateur from './AjoutOperateur';
import { Oval } from 'react-loader-spinner';
import AjoutCommission from './AjoutCommission';
import { rechercherallcommission } from '../../services/parametres/commission';

function Parametres() {
    const [ongletActif, setongletActif] = useState({
        pays: true,
        operateur: false,
        commission: false
    });
    const [listePays, setlistePays] = useState([]);
    const [listeOperateur, setlisteOperateur] = useState([]);
    const [listeCommission, setlisteCommission] = useState([]);
    const [infoOperateur, setinfoOperateur] = useState({})
    const [infoCommission, setinfoCommission] = useState({})
    const [loading, setloading] = useState(false)
    const hangleOngletActif = (val) => {
        if (val === 1) {
            setongletActif({
                pays: true,
                operateur: false,
                commission: false
            });
        }
        else if (val === 2) {
            setongletActif({
                pays: false,
                operateur: true,
                commission: false
            });
        }
        else if (val === 3) {
            setongletActif({
                pays: false,
                operateur: false,
                commission: true
            });
        }
    }

    //Affiche des pays
    const afficherPays = () => {
        setloading(true)
        rechercherpays().then((res) => {
            setloading(false)
            setlistePays(res.data.donnee);
        }).catch(() => {
            setloading(false)
        })
    }

    //Affichage des opérateurs
    const afficherOperateurs = () => {
        setloading(true)
        rechercheroperateurs().then((res) => {
            setloading(false);
            console.log(res.data.donnee);
            setlisteOperateur(res.data.donnee);
        }).catch((error) => {
            setloading(false);
            console.log(error.response.data);
        })
    }

    const affichercommission = () => {
        setloading(true)
        rechercherallcommission().then((res) => {
            setloading(false);
            setlisteCommission(res.data.donnee);
        }).catch(() => {
            setloading(false)
        })
    }

    //Changement d'état de l'opérateur
    const handleStatut = (code) => {
        changeretatoperateur(code).then((res) => {
            afficherOperateurs();
        })
    }

    const handleSuppressionPays = (id) => {
        supprimerpays(id).then((res) => {
            afficherPays();
        })
    }

    const filterOnPays = (e, type) => {
        setloading(true)
        if (type === "COMMISSION") {

            rechercherallcommission({ pays_id: e.target.value }).then((res) => {
                setloading(false);
                setlisteCommission(res.data.donnee);
            }).catch(() => {
                setloading(false)
            })
        } else if(type === "OPERATEUR"){
            if(e.target.value !== ""){
                rechercheroperateurparpays(e.target.value).then((res)=>{
                    setloading(false);
                    setlisteOperateur(res.data.donnee);
                }).catch((error)=>{
                    setloading(false);
                    console.log(error.response.data);
                })
            }else{
                afficherOperateurs();
            }
        }
    }

    useEffect(() => {
        afficherPays();
        afficherOperateurs();
        affichercommission();
    }, []);

    const optionListePays = listePays.map((item, index) => (
        <option key={index} value={item.id}>{item.designation}</option>
    ))

    const rowlistPays = listePays.map((item) => (
        <tr key={item.id}>
            <td>
                <h4 className="ui image header">
                    <img src={"https://www.drapeauxdespays.fr/data/flags/w702/" + item.id.toLowerCase() + ".png"} className="ui mini rounded image" />
                    <div className="content">
                        {item.designation}
                    </div>
                </h4></td>
            <td>
                {item.id}
            </td>
            <td>
                <a onClick={() => handleSuppressionPays(item.id)} className='text-center text-danger'> <i className='fa fa-trash-alt'></i></a>
            </td>
        </tr>
    ));

    const rowListOperateur = listeOperateur.map((item) => (
        <tr key={item.id}>
            <td>
                <h4 className="ui image header">
                    <img src={"https://www.drapeauxdespays.fr/data/flags/w702/" + item.pays[0].id.toLowerCase() + ".png"} className="ui mini rounded image" />
                    <div className="content">
                        {item.pays[0].designation}
                    </div>
                </h4></td>
            <td>
                {item.id}
            </td>
            <td>{item.designation}</td>
            <td>{item.classeTraitement}</td>
            <td>
                <button type='button' onClick={() => handleStatut(item.id)} className='btn btn-sm'>
                    {item.typeEtat === "ACTIF" ? <span className='btn text-success'><i className='fa fa-sign-in-alt'></i> Actif</span> : <span className='btn text-danger'> <i className='fa fa-ban'></i> Inactif</span>}
                </button>
                <button type='button' data-bs-toggle="modal" data-bs-target="#ajoutoperateur" onClick={() => setinfoOperateur(item)} className='btn btn-sm'>
                    <i className='fa fa-pencil-alt'></i>
                </button>
            </td>
        </tr>
    ));

    const rowListCommission = listeCommission.map((item, index) => (
        <tr key={index}>
            <td> {
                item.pays !== null &&
                <h4 className="ui image header">
                    <img src={"https://www.drapeauxdespays.fr/data/flags/w702/" + item.pays.id.toLowerCase() + ".png"} className="ui mini rounded image" />
                    <div className="content">
                        {item.pays.designation}
                    </div>
                </h4>
            }
            </td>
            <td>{
                (item.operateurmobilemoney !== undefined) && <span>{item.operateurmobilemoney.designation}</span>
            }
            </td>
            <td>{item.typeCommission}</td>
            <td>{item.valeur}</td>
            <td>
                <button type='button' data-bs-toggle="modal" data-bs-target="#ajoutcommission" onClick={() => setinfoCommission(item)} className='btn btn-sm'>
                    <i className='fa fa-pencil-alt'></i>
                </button>
            </td>
        </tr>
    ));

    return <Layout>
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <a className={ongletActif.pays ? "nav-link active text-white" : "nav-link"} onClick={() => hangleOngletActif(1)}>Pays</a>

            </li>
            <li className="nav-item">
                <a className={ongletActif.operateur ? "nav-link active text-white" : "nav-link"} onClick={() => hangleOngletActif(2)}>Opérateurs</a>
            </li>
            <li className="nav-item">
                <a className={ongletActif.commission ? "nav-link active text-white" : "nav-link"} onClick={() => hangleOngletActif(3)}>Commission</a>
            </li>
        </ul>

        {
            ongletActif.pays && <div className='mt-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>
                        <legend className='fw-bold'>Pays</legend>
                    </div>
                    <div className='d-flex'>
                        <div>
                            <a type='button' data-bs-toggle="modal" data-bs-target="#ajoutPays" className='btn colorYelloSkanPay text-black btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau pays</a>
                            <AjouPays afficherPays={afficherPays} />
                        </div>

                    </div>
                </div>

                <table className="ui celled padded table">
                    <thead>
                        <tr><th>Pays</th>
                            <th width="15%">Code pays</th>
                            <th width="15%">Action rapide</th>
                        </tr></thead>
                    <tbody>
                        {
                            rowlistPays
                        }
                    </tbody>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
            </div>
        }


        { //Opérateurs
            ongletActif.operateur && <div className='mt-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>
                        <legend className='fw-bold'>Opérateurs</legend>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <select onChange={(e) => filterOnPays(e, "OPERATEUR")} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                <option value="">Sélectionner le pays</option>
                                {optionListePays}
                            </select>
                        </div>

                        <div>
                            <a type='button' onClick={() => setinfoOperateur({})} data-bs-toggle="modal" data-bs-target="#ajoutoperateur" className='btn colorYelloSkanPay text-black btn-sm'> <i className='fa fa-plus-circle'></i> Nouveau opérateur</a>
                            <AjoutOperateur data={infoOperateur} afficherOperateurs={afficherOperateurs} />
                        </div>

                    </div>
                </div>

                <table className="ui celled padded table">
                    <thead>
                        <tr><th>Pays</th>
                            <th>Code</th>
                            <th>Opérateur</th>
                            <th>Classe de traitement</th>
                            <th width="15%">Action rapide</th>
                        </tr></thead>
                    <tbody>
                        {rowListOperateur}
                    </tbody>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
            </div>
        }

        {
            ongletActif.commission && <div className='mt-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>
                        <legend className='fw-bold'>Commissions</legend>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <select onChange={(e) => filterOnPays(e, "COMMISSION")} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                <option value="">Sélectionner le pays</option>
                                {optionListePays}
                            </select>
                        </div>
                        <div>
                            <a type='button' onClick={() => setinfoCommission({})} data-bs-toggle="modal" data-bs-target="#ajoutcommission" className='btn colorYelloSkanPay text-black  btn-sm'> <i className='fa fa-plus-circle'></i> Nouvelle commission</a>
                            <AjoutCommission data={infoCommission} affichercommission={affichercommission} />
                        </div>

                    </div>
                </div>

                <table className="ui celled padded table">
                    <thead>
                        <tr><th>Pays</th>
                            <th>Opérateur</th>
                            <th>Type commission</th>
                            <th>Valeur</th>
                            <th width="15%">Action rapide</th>
                        </tr></thead>
                    <tbody>
                        {rowListCommission}
                    </tbody>
                </table>
                {loading &&
                    <div className='d-flex justify-content-center'>
                        <Oval width={30} height={30} color="#344b7b" visible={loading} />
                    </div>
                }
            </div>
        }
    </Layout>;
}

export default Parametres;
