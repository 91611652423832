import React, { useState } from 'react';
import DatePicker from 'react-datepicker'
// import fr from '@fullcalendar/core/locales/fr'
import fr from 'date-fns/locale/fr';


function DateFilter(props) {

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const changeDateDebut = (dateDebut) =>{
    console.log(dateDebut)
  }

  return (
    <div className='d-flex'>
      <DatePicker
        selected={startDate}
        locale={fr}
        placeholderText="Date début"
        className="form-control form-control-sm"
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={new Date()}
        endDate={endDate}
      />
      
      <DatePicker
        selected={endDate}
        locale={fr}
        className="mx-1 form-control form-control-sm"
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        placeholderText="Date fin"
        endDate={new Date()}
        minDate={startDate}
      />
        <button onClick={()=>props.filterElement(startDate, endDate)} className="btn colorYelloSkanPay btn-sm mx-2 text-white"><i className="fa fa-search"></i></button>
    </div>
  );
}
export default DateFilter;