import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik, yupToFormErrors } from 'formik';
import * as yup from 'yup'
import { rechercherpestataireservice, rechercherserviceparprestataire } from '../../services/prestataires/service';
import { enregistrerreversement } from '../../services/reversement/reversementRequest';
import $ from 'jquery';
import { rechercherprestatairestats } from '../../services/statistiques/StatistiquesRequest';
import Swal from 'sweetalert2';
import { Oval } from 'react-loader-spinner';
import { changeretatreversement } from '../../services/demande-reversement/DemandeRequest';


function AjoutReversement(props) {

    const [listeService, setlisteService] = useState([]);
    const [montantApayer, setmontantApayer] = useState("");
    const [submitValues, setSubmitValues] = useState({});
    let [recupereServiceIdAndprestataireId, setRecupereServiceIdAndprestataireId] = useState({
        service_id: "",
        prestataires_id: ""
    });
    const [LabelServiceSelectionner, setLabelServiceSelectionner] = useState("");
    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("")
    const [open, setOpen] = useState(false);


    const initialValues = {
        id: "",
        dateVersement: "",
        prestataires_id: "",
        service_id: "",
        resteAPayer: "",
        montant: "",
        commentaire: ""
    }

    const validationValuesSchema = yup.object().shape({
        dateVersement: yup.date().required("Veuillez sélectionner une date"),
        montant: yup.number().required("Veuillez entrer le montant")
    })

    let val = {};

    const handleOnsubmitReversement = (values) => {
        val = values
        val.prestataire_id = recupereServiceIdAndprestataireId.prestataires_id
        val.service_id = recupereServiceIdAndprestataireId.service_id
        document.getElementById("service_id").value !== "" && setLabelServiceSelectionner(document.getElementById("service_id").options[document.getElementById("service_id").selectedIndex].text);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success mx-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: `Êtes-vous sûre de vouloir effectuer ce reversement ?`,
            text: "Service : " + LabelServiceSelectionner + " , Montant : " + values.montant + " FCFA",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setloading(true)
                enregistrerreversement(val).then((res) => {
                    changeretatreversement(val.service_id).then(() => {
                        setloading(false)
                        props.afficherreversements(1);
                        swalWithBootstrapButtons.fire(
                            'Enregistrer!',
                            'Votre reversement a bien été enregistré.',
                            'success'
                        )
                        setlisteService([]);
                        setmontantApayer("");
                        setmessageError("");
                        props.setPageActive(1);
                        document.getElementById("resteAPayer").value = "";
                        setOpen(false);
                        $('#fermer').trigger('click');
                    }).catch(()=>{
                        setloading(false)
                    })


                }).catch((error) => {
                    setloading(false);
                    console.log(error.response.data);
                    if (typeof error.response.data.donnee === 'object') {
                        error.response.data.donnee.service_id !== undefined && setmessageError("Veuillez sélectionner le service")
                        error.response.data.donnee.prestataire_id !== undefined && setmessageError("Veuillez sélectionner le prestataire")

                    } else if (typeof error.response.data.donnee === 'string') {
                        setmessageError(error.response.data.donnee)
                    }

                })


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Annulé',
                    'Le reverserver a été annulé',
                    'error'
                )
            }
        })




    }


    const handleService = (e) => {
        recupereServiceIdAndprestataireId.prestataires_id = e.target.value;
        recupereServiceIdAndprestataireId.service_id = "";
        setRecupereServiceIdAndprestataireId(recupereServiceIdAndprestataireId);

        if (recupereServiceIdAndprestataireId.prestataires_id !== "") {
            rechercherserviceparprestataire(recupereServiceIdAndprestataireId.prestataires_id).then((res) => {
                setlisteService(res.data.donnee);
                afficherResteApayer()
            })
        } else {
            setlisteService([]);
            setmontantApayer("");
        }
    }
    const recuperationResteApayer = (e) => {

        recupereServiceIdAndprestataireId.service_id = e.target.value;
        setLabelServiceSelectionner(document.getElementById("service_id").options[document.getElementById("service_id").selectedIndex].text)
        setRecupereServiceIdAndprestataireId(recupereServiceIdAndprestataireId);
        if (recupereServiceIdAndprestataireId.prestataires_id === "" && recupereServiceIdAndprestataireId.service_id === "") { setmontantApayer("") }
        else {
            afficherResteApayer()
        }

    };

    const afficherResteApayer = () => {
        rechercherprestatairestats(recupereServiceIdAndprestataireId).then((res) => {
            setmontantApayer(res.data.donnee);
        })
    }

    const optionListPrestataire = props.listePrestataire.map((item) => (
        <option key={item.id} value={item.id}>{item.nom}</option>
    ));

    const optionListservice = listeService.map((item) => (
        <option key={item.id} value={item.id}>{item.designation}</option>
    ));


    return <div className="modal fade" id="ajoutReversement" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content blueSkanpay">
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="staticBackdropLabel">REVERSEMENT</h5>
                    <button type="button" className="bg-danger btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white">

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationValuesSchema}
                        onSubmit={handleOnsubmitReversement}
                    >
                        {({ resetForm }) => (
                            <Form>
                                {messageError !== "" && <p className='text-danger'><i className='fa fa-exclamation-triangle'></i> {messageError}</p>}

                                <div className="mb-3">
                                    <label className="form-label " htmlFor="dateVersement">Date de réversement</label>
                                    <Field className="form-control" type="date" id="dateVersement" name="dateVersement" />
                                    <p className='text-danger'><ErrorMessage name="dateVersement"></ErrorMessage></p>
                                </div>
                                <div className='mb-3' >
                                    <label htmlFor="prestataires_id" className='form-label'>Prestataire</label>
                                    <Field component="select" onChange={handleService} value={recupereServiceIdAndprestataireId.prestataires_id} className='form-control form-select' id='prestataires_id' name="prestataires_id" >
                                        <option value="">Sélectionner le prestataire</option>
                                        {optionListPrestataire}
                                    </Field>
                                    <p className='text-danger'><ErrorMessage name="prestataires_id"></ErrorMessage></p>
                                </div>
                                <div className='mb-3' >
                                    <label htmlFor="service_id" className='form-label'>Service</label>
                                    <Field component="select" onChange={recuperationResteApayer} value={recupereServiceIdAndprestataireId.service_id} className='form-control form-select' id='service_id' name="service_id" >
                                        <option value="">Sélectionner le service</option>
                                        {optionListservice}
                                    </Field>
                                    <p className='text-danger'><ErrorMessage name="service_id"></ErrorMessage></p>
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="resteAPayer" className="form-label">Rester à reverser</label>
                                    <Field className="form-control text-danger fs-5 fw-bold" name="resteAPayer" id="resteAPayer" value={montantApayer} disabled />
                                    {/* <p id="resteAPayer" className='fw-bold text-danger'>{montantApayer}</p> */}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="montant" className="form-label">Montant reverser</label>
                                    <Field type="number" className="form-control" id="montant" name="montant" />
                                    <p className='text-danger'><ErrorMessage name="montant"></ErrorMessage></p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="commentaire" className='form-label'>Commentaire</label>
                                    <Field as="textarea" name="commentaire" id="commentaire" className="form-control"></Field>
                                </div>

                                <div className="modal-footer bg-white">
                                    <button id='fermer' type="button" onClick={() => {
                                        resetForm(); setmessageError(""); setlisteService([]); setmontantApayer(""); setRecupereServiceIdAndprestataireId({
                                            service_id: "",
                                            prestataires_id: ""
                                        })
                                    }}
                                        className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    <Oval width={30} height={30} color="#344b7b" visible={loading} />
                                    {!loading && <button type="submit" className="btn btn-sm btn-primary">Enregistrer</button>}

                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    </div>
}


export default AjoutReversement;
