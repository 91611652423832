import React, { useState } from "react";
import Pagination from "react-js-pagination";

function Paginate(props) {

    return (
        <div>
            <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={props.pageActive}
                itemsCountPerPage={props.nbreElementParPage}
                totalItemsCount={props.nbreTotalElmentPage}
                pageRangeDisplayed={5}
                onChange={props.handlePageChange}
            />
        </div>
    )
}

export default Paginate;
